import React, { useState, useEffect } from "react";
import AdminService from "../../../services/admin.service";
import { useFormik } from "formik";
import { Modal, Button, Row, Col, Form, Container } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Upload from "../../../images/upload.png";
import Validations from "../../../validations/validations";
import AuthService from "../../../services/auth-service";
import Cities from "../../cities.json";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import ErrorMessage from "../../commonModules/ErrorMessage";

const OnBoardingPage = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);
  const [popupMessage1, setPopupMessage1] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cities, setCities] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);

  useEffect(() => {
    const uniqueStates = [...new Set(Cities.map((item) => item.state))];
    setCities(
      Cities.map((item) => ({
        label: item.name,
        value: item.name,
        state: item.state,
      }))
    );
    setAllStates(uniqueStates.map((state) => ({ label: state, value: state })));
    setFilteredStates(
      uniqueStates.map((state) => ({ label: state, value: state }))
    );
  }, []);

  const handleCityChange = (selectedCityId) => {
    const selectedCity = cities.find((city) => city.value === selectedCityId);
    console.log("selectedCity", selectedCity);
    if (selectedCity) {
      setFilteredStates([
        { label: selectedCity.state, value: selectedCity.state },
      ]);
      formik.setFieldValue("state", selectedCity.state); // Automatically set the state in formik
    } else {
      setFilteredStates(allStates); // Reset to all states if no city is selected
      formik.setFieldValue("state", ""); // Reset the state in formik
    }
  };

  // const handleFileInputChange = async (event) => {
  //   setSelectedFile(event.target.files[0]);
  //   const file = event.target.files[0];
  //   console.log("file", file);

  //   const formDataToSend = new FormData();
  //   formDataToSend.append("file", file);
  //   try {
  //     const res = await AdminService.uploadDocument(formDataToSend);
  //     console.log("res after upload doc", res.data.data);
  //     formik.setFieldValue("educationDocumentId", res.data.data);
  //   } catch (error) {
  //     console.error("Error uploading document:", error);
  //   }
  // };

  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileInput2Change = async (event) => {
    setSelectedFile2(event.target.files[0]);
    const file = event.target.files[0];
    console.log("file", file);
    const formDataToSend = new FormData();
    formDataToSend.append("file", file);
    try {
      const res = await AdminService.uploadDocument(formDataToSend);
      console.log("res after upload doc", res.data.data);
      formik.setFieldValue("bankDocumentId", res.data.data);
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const validationSchema = Validations.OnboardingPageValidation;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pinCode: "",
      gender: "",
      maritalStatus: "",
      high_Qualification: "",
      board: "",
      educationDocumentId: "",
      accountNo: "",
      ifscCode: "",
      accHolderName: "",
      bankDocumentId: "",
      createdBy: "",
      role: "POSP",
      status: "DEACTIVE",
      phoneNumber: "",
      panNo: "",
      aadharNo: "",
      pospNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", formik.values);
      await AuthService.onBoarding(formik.values).then(async (response) => {
        // try {
        //   let to = response?.data?.data?.email;
        //   let cc = ["tejashrik@comsenseconsulting.com"];
        //   let subject = ` Welcome to Policy365 Agent Portal - Account Creation Confirmation`;

        //   let content = `
        // <!DOCTYPE html>
        // <html>

        // <body>
        //     <p>Dear,</p>

        //     <p>We are pleased to inform you that your account has been successfully created in the Policy365 Agent Portal.
        //     Below, you will find your login credentials:</p>

        //     <p><strong>Username:</strong>${response?.data?.data?.email}<br>
        //     <strong>Password:</strong>${response?.data?.data?.password} </p>

        //     <p>To access the Agent Portal, please visit <a href=${process.env.REACT_APP_FRONTEND_SERVER}/login>Login</a> and enter your credentials.</p>
        //     <p>If you have any questions or need assistance, please do not hesitate to contact our support team at <a href="mailto:contact@policies365.com">contact@policies365.com</a> or 022-62526252.</p>

        //     <p>Thank you for joining us, and we look forward to working with you.</p>

        //     <p>Best regards,</p>

        //     <p>Team Policy365<br>
        //     <img src=${Logo} alt="Policy365 Logo"></img>

        //     <hr>

        //     <p>Please remember to keep your login credentials confidential and secure. If you suspect any unauthorized access to your account, contact us immediately.</p>

        //     <hr>

        //     <p>This is an automated message; please do not reply directly to this email. If you need assistance, contact our support team.</p>
        // </body>
        // </html>
        // `;

        //   let data = { to, cc, subject, content };
        //   await MailService.sendMail(data).then((res) => {
        //     console.log("data after mail", res.data);
        //   });
        // } catch (error) {
        //   console.log("Error", error);
        // }
        setPopupMessage(response.data.data.email);
        setPopupMessage1(response.data.data.password);
        setShowPopup(true);
      });
    },
  });

  useEffect(() => {
    document.body.classList.add("car-page");
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  useEffect(() => {
    handleCityChange(formik.values.city);
  }, [formik.values.city]);

  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href = "/";
  };

  return (
    <>
      <Container className="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px] py-[50px] max-[1199px]:py-[35px]">
        <br />
        <h3 style={{fontSize:'1.75rem', margin:'0 auto'}}>Register As POSP</h3>

        <Form className="mb-2" onSubmit={formik.handleSubmit}>
          <Row style={{ textAlign: "left" }}>
            <h4 className="mb-3 left-align mt-5 align-left">
              Personal Details
            </h4>
            <p className="text-left mb-4">
              Please enter your personal details as mentioned in your identity
              proof document.
            </p>
            <Col className="mb-5">
              <div className="form-bg">
                <Row style={{ textAlign: "left" }}>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="First Name"
                      className=""
                    >
                      <Form.Control
                        name="firstName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        className="floating-input"
                      />

                      <Form.Text className="text-danger">
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-danger">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </Form.Text>
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Last Name"
                      className=""
                    >
                      <Form.Control
                        name="lastName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="text-danger">
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email ID"
                      className=""
                    >
                      <Form.Control
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </Form.Text>
                  </Col>

                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Phone Number"
                      className=""
                    >
                      <Form.Control
                        name="phoneNumber"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="text-danger">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="POSP Number"
                      className=""
                    >
                      <Form.Control
                        name="pospNumber"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pospNumber}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.pospNumber && formik.errors.pospNumber ? (
                        <div className="text-danger">
                          {formik.errors.pospNumber}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="PAN No"
                      className=""
                    >
                      <Form.Control
                        name="panNo"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.panNo}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.panNo && formik.errors.panNo ? (
                        <div className="text-danger">{formik.errors.panNo}</div>
                      ) : null}
                    </Form.Text>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Aadhar No"
                      className=""
                    >
                      <Form.Control
                        name="aadharNo"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.aadharNo}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.aadharNo && formik.errors.aadharNo ? (
                        <div className="text-danger">
                          {formik.errors.aadharNo}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>

                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Address"
                      className=""
                    >
                      <Form.Control
                        name="address"
                        type="textarea"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                        className="floating-input"
                        as="textarea"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.address && formik.errors.address ? (
                        <div className="text-danger">
                          {formik.errors.address}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Pin Code"
                      className=""
                    >
                      <Form.Control
                        name="pinCode"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pinCode}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.pinCode && formik.errors.pinCode ? (
                        <div className="text-danger">
                          {formik.errors.pinCode}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                  <Col md={6} sm={12} className="mb-3">
                    <InputFieldDropdown
                      formikFieldName="city"
                      label="City*"
                      optionsArray={cities}
                      formik={formik}
                    />
                    <ErrorMessage formik={formik} fieldValue="city" />
                  </Col>
                  <Col md={6} sm={12} className="mb-3">
                    <InputFieldDropdown
                      formikFieldName="state"
                      label="State*"
                      optionsArray={filteredStates}
                      formik={formik}
                    />
                    <ErrorMessage formik={formik} fieldValue="state" />
                  </Col>

                  <Col sm={12} md={6} className="mb-3">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Gender"
                      className=""
                    >
                      <Form.Select
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.gender}
                        className="floating-input"
                      >
                        <option value="">Select...</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </Form.Select>
                      {/* <ErrorMessage formik={formik} fieldValue="gender" /> */}
                      <Form.Text className="text-danger">
                        {formik.touched.gender && formik.errors.gender ? (
                          <div className="text-danger">
                            {formik.errors.gender}
                          </div>
                        ) : null}
                      </Form.Text>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6} className="mb-3">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Marital Status"
                      className=""
                    >
                      <Form.Select
                        name="maritalStatus"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.maritalStatus}
                        className="floating-input"
                      >
                        <option value="">Select...</option>
                        <option value="MARRIED">Married</option>
                        <option value="UNMARRIED">Unmarried</option>
                      </Form.Select>
                      {/* <ErrorMessage formik={formik} fieldValue="maritalStatus" /> */}
                      <Form.Text className="text-danger">
                        {formik.touched.maritalStatus &&
                        formik.errors.maritalStatus ? (
                          <div className="text-danger">
                            {formik.errors.maritalStatus}
                          </div>
                        ) : null}
                      </Form.Text>
                    </FloatingLabel>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ textAlign: "left" }}>
            <h4 className="mb-3 left-align mt-2 align-left">
              Education Details
            </h4>
            <p className="text-left mb-4">
              Please enter your Educational details. The minimum criterion is
              ssc or hsc standard pass.
            </p>
            <Col className="mb-5">
              <div className="form-bg">
                <Row style={{ textAlign: "left" }}>
                  <Col md={6} lg={6} sm={12} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Highest Qualification"
                      className=""
                    >
                      <Form.Select
                        name="high_Qualification"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.high_Qualification}
                        className="floating-input"
                      >
                        <option value="">Select...</option>
                        <option value="HSC">HSC</option>
                        <option value="SSC">SSC</option>
                        <option value="Graduation">Graduate</option>
                        <option value="postGraduation">Post Graduate</option>
                      </Form.Select>
                      {/* <ErrorMessage formik={formik} fieldValue="highestQualification" /> */}
                      <Form.Text className="text-danger">
                        {formik.touched.high_Qualification &&
                        formik.errors.high_Qualification ? (
                          <div className="text-danger">
                            {formik.errors.high_Qualification}
                          </div>
                        ) : null}
                      </Form.Text>
                    </FloatingLabel>
                  </Col>

                  <Col sm={12} md={6} lg={6} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Board/University"
                      className=""
                    >
                      <Form.Control
                        name="board"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.board}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.board && formik.errors.board ? (
                        <div className="text-danger">{formik.errors.board}</div>
                      ) : null}
                    </Form.Text>
                  </Col>

                  {/* <Col sm={12} md={6} lg={4} className="pt-2">
                    <div className="upload-btn mt-2">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        accept=".jpeg, .jpg, .png"
                        onChange={handleFileInputChange}
                        hidden
                      />
                      <label
                        className="text-center"
                        htmlFor="file"
                        title={selectedFile ? selectedFile.name : ""}
                      >
                        {selectedFile ? (
                          selectedFile.type.startsWith("image") ? (
                            <>
                              <img
                                className="mb-2"
                                src={URL.createObjectURL(selectedFile)}
                                alt="Preview"
                                style={{
                                  maxWidth: "150px",
                                  maxHeight: "200px",
                                }}
                              />
                              <br />
                              <span
                                className="text-hidden"
                                title={selectedFile.name}
                              >
                                {selectedFile.name}
                              </span>
                            </>
                          ) : selectedFile.type === "application/pdf" ? (
                            <>
                              <embed
                                src={URL.createObjectURL(selectedFile)}
                                type="application/pdf"
                                style={{ width: "150px", height: "200px" }}
                              />
                              <br />
                              <span
                                className="text-hidden"
                                title={selectedFile.name}
                              >
                                {selectedFile.name}
                              </span>
                            </>
                          ) : (
                            selectedFile.name
                          )
                        ) : (
                          <>
                            <img className="mb-2" src={Upload} width={"20"} />
                            'Browse for file to upload'
                          </>
                        )}
                      </label>
                    </div>
                    <Form.Text className="text-danger">
                      {formik.touched.fileUpload && formik.errors.fileUpload ? (
                        <div className="text-danger">
                          {formik.errors.fileUpload}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>/ */}
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ textAlign: "left" }}>
            <h4 className="mb-3 left-align mt-2 align-left">Bank Details</h4>
            <p className="text-left mb-4">
              Please enter your accurate bank details as appearing in your bank
              statement. Upload a copy of your cancelled cheque or the first two
              pages of your updated passbook to validate the details.
            </p>
            <Col className="mb-5">
              <div className="form-bg">
                <Row style={{ textAlign: "left" }}>
                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Account Number"
                      className=""
                    >
                      <Form.Control
                        name="accountNo"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.accountNo}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.accountNo && formik.errors.accountNo ? (
                        <div className="text-danger">
                          {formik.errors.accountNo}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>

                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="IFSC Code"
                      className=""
                    >
                      <Form.Control
                        name="ifscCode"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ifscCode}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.ifscCode && formik.errors.ifscCode ? (
                        <div className="text-danger">
                          {formik.errors.ifscCode}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>

                  <Col sm={12} md={6} lg={4} className="pt-2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Account Holder Name"
                      className=""
                    >
                      <Form.Control
                        name="accHolderName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.accHolderName}
                        className="floating-input"
                      />
                    </FloatingLabel>
                    <Form.Text className="text-danger">
                      {formik.touched.accHolderName &&
                      formik.errors.accHolderName ? (
                        <div className="text-danger">
                          {formik.errors.accHolderName}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>

                  <Col sm={12} md={12} lg={4} className="pt-2">
                    <div className="custom-upload-btn mt-2">
                      <input
                        type="file"
                        id="file2"
                        name="file2"
                        accept=".jpeg, .jpg, .png"
                        onChange={handleFileInput2Change}
                        hidden
                      />

                      <label htmlFor="file2">
                        <p className="mb-0">
                          {selectedFile2 ? (
                            selectedFile2.type.startsWith("image") ? (
                              <>
                                <img
                                  className="mb-2"
                                  src={URL.createObjectURL(selectedFile2)}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "150px",
                                    maxHeight: "200px",
                                  }}
                                />
                                <br />
                                <span
                                  className="text-hidden"
                                  title={selectedFile2.name}
                                >
                                  {selectedFile2.name}
                                </span>
                              </>
                            ) : selectedFile2.type === "application/pdf" ? (
                              <>
                                <embed
                                  src={URL.createObjectURL(selectedFile2)}
                                  type="application/pdf"
                                  style={{ width: "150px", height: "200px" }}
                                />
                                <br />
                                <span
                                  className="text-hidden"
                                  title={selectedFile2.name}
                                >
                                  {selectedFile2.name}
                                </span>
                              </>
                            ) : (
                              selectedFile2.name
                            )
                          ) : (
                            <>
                              <img className="mb-2" src={Upload} width={"20"} />
                              'Browse for file to upload'
                            </>
                          )}
                        </p>
                      </label>
                    </div>
                    <Form.Text className="text-danger">
                      {formik.touched.fileUpload && formik.errors.fileUpload ? (
                        <div className="text-danger">
                          {formik.errors.fileUpload}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ textAlign: "left" }}>
            <Col className="mb-5">
              <Row style={{ textAlign: "left" }}>
                <Col sm={12} md={12} className="d-flex">
                  <Button className="primary-btn" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>

      {showPopup && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>
              Congratulations on you new account creation.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Email : {popupMessage}</Modal.Body>
          <Modal.Body>Password : {popupMessage1}</Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="primary-btn"
              onClick={handleClosePopup}
            >
              Okay!
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default OnBoardingPage;
