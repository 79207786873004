import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import disclaimer from "../../components/style/assets/img/insurance/disclaimer.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Disclaimer() {
  return (
    <React.Fragment>
      <>
        <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full">
              <div class="w-full px-[12px]">
                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                      Disclaimer
                    </h2>
                  </div>
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                      <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                        <a
                          href="index.html"
                          class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                        >
                          Home
                        </a>
                      </li>
                      <li class="text-[14px] font-normal px-[5px]">
                        <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                      </li>
                      <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                        Disclaimer
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-blog-details py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap mb-[-24px]">
              <div class="min-[1200px]:w-[100%] min-[992px]:w-[58.33%] w-full px-[12px] mb-[24px]">
                <div
                  class="bb-blog-details-contact"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  {/* <div class="inner-blog-details-image mb-[24px]">
                                        <img src={disclaimer} alt="details-one" class="w-full rounded-[15px]" />

                                    </div> */}

                  <div class="inner-blog-details-image mb-[24px] flex">
                    <div className="w-[45%]">
                      <img
                        src={disclaimer}
                        alt="details-one"
                        class="w-full rounded-[15px]"
                      />
                    </div>
                    <div class="min-[1200px]:w-[55%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                      <div class="bb-blog-sidebar mb-[-24px]">
                        <div
                          class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div class="blog-sidebar-title mb-[20px]">
                            <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px] text-left">
                              Get In Touch
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner-blog-details-contact mb-[30px] text-left">
                    <h4 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Disclaimer
                    </h4>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Introduction
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Policies 365 strives to provide clear, accurate, and
                      responsible information about our products and services
                      through our website and other online platforms
                      (collectively referred to as the "Platform"). By accessing
                      and using our Platform, you agree to the terms of this
                      disclaimer.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Accuracy of Information
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We make every effort to ensure that the information and
                      data provided on our Platform are accurate and up-to-date.
                      However, the information is generic in nature and should
                      not be considered as comprehensive or fully detailed. We
                      are not responsible for any discrepancies or omissions.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Intellectual Property
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      There is no intention to violate any intellectual property
                      or ancillary rights. All information on the Platform is
                      subject to change at our discretion without prior notice.
                      Any changes of public utility will be communicated
                      promptly on the Platform.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Use of Information
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      The information provided on the Platform is for general
                      informational and educational purposes only. It is not
                      intended to be a substitute for professional advice or
                      recommendations for any specific insurance products. Users
                      should verify the information independently before making
                      any decisions based on it. Navnit Insurance is not
                      responsible for any loss, harm, or damage arising from the
                      use of information provided on the Platform.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Limitation of Liability
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      To the fullest extent permitted by law, Navnit Insurance,
                      its affiliates, directors, employees, agents, and
                      associated staff will not be liable for any direct,
                      indirect, incidental, consequential, or special damages
                      arising out of or in any way connected with the use of the
                      Platform. This includes but is not limited to any errors
                      or omissions in the content, any actions taken in reliance
                      on the information provided on the Platform, or any loss
                      or damage resulting from unauthorized access to or use of
                      our servers.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Customer Discretion
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      It is the responsibility of the customer to understand the
                      limitations of insurance policies and the risks involved.
                      We recommend that customers read all relevant documents
                      carefully before making any decisions. The use of the
                      Platform and reliance on its content is at the customer's
                      own risk.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Third-Party Links
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      The Platform may contain links to third-party websites or
                      services that are not owned or controlled by Navnit
                      Insurance. We do not endorse or assume any responsibility
                      for the content, privacy policies, or practices of any
                      third-party websites or services. Users are advised to
                      read the privacy policies and terms of use of any
                      third-party websites they visit.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Governing Law
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Access to and use of the Platform are governed by the
                      applicable laws of India. By using the Platform, you agree
                      to submit to the exclusive jurisdiction of the courts in
                      Mumbai, India.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Changes to this Disclaimer
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Navnit Insurance reserves the right to modify this
                      disclaimer at any time. Any changes will be posted on this
                      page, and your continued use of the Platform constitutes
                      your acceptance of the updated disclaimer.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
