import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import privacyPolicy from "../../components/style/assets/img/insurance/privacy-policy.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <>
        <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full">
              <div class="w-full px-[12px]">
                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                      Privacy Policy
                    </h2>
                  </div>
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                      <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                        <a
                          href="index.html"
                          class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                        >
                          Home
                        </a>
                      </li>
                      <li class="text-[14px] font-normal px-[5px]">
                        <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                      </li>
                      <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                        Privacy Policy
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-blog-details py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap mb-[-24px]">
              <div class="min-[1200px]:w-[100%] min-[992px]:w-[58.33%] w-full px-[12px] mb-[24px]">
                <div
                  class="bb-blog-details-contact"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <div class="inner-blog-details-image mb-[24px] flex">
                    <div className="w-[45%]">
                    <img
                      src={privacyPolicy}
                      alt="details-one"
                      class="w-full rounded-[15px]"
                    />
                    </div>
                    <div class="min-[1200px]:w-[55%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                      <div class="bb-blog-sidebar mb-[-24px]">
                        <div
                          class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div class="blog-sidebar-title mb-[20px]">
                            <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px] text-left">
                              Get In Touch
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner-blog-details-contact mb-[30px] text-left">
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Privacy Policy
                    </h3>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Introduction
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Navnit Insurance Broking Pvt. Ltd. (hereinafter referred
                      to as "Navnit Insurance," "we," "us," or "our") values
                      your privacy and is committed to protecting your personal
                      information. This Privacy Policy outlines how we collect,
                      use, store, handle, and disclose your information when you
                      use our website, mobile applications, and other online
                      services (collectively referred to as the "Platform").
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Compliance with Laws
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      This Privacy Policy is published in compliance with:
                    </p>
                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            The Information Technology Act, 2000
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            The Information Technology (Reasonable Security
                            Practices and Procedures and Sensitive Personal
                            Information) Rules, 2011 (the "SPDI Rules"), as
                            amended from time to time
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Digital personal data protection act 2023
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      By using our Platform and/or registering on our website,
                      you agree to this Privacy Policy.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Controllers of Personal Information
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Navnit Insurance Broking Pvt. Ltd. will store and collect
                      your personal data.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Collection of Personal Information
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We collect and store your personal information provided by
                      you from time to time to offer a safe, efficient, and
                      customized experience. This may include:
                    </p>

                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Contact details such as name, email address, mailing
                            address, phone number, and IP address.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Government-issued identity documents like PAN,
                            Aadhaar, driver's license, voter ID card, etc.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Non-personally identifiable information such as
                            browser name, operating system, and internet service
                            provider.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Financial information for payment purposes.
                          </span>
                        </li>
                      </ul>
                    </div>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Use of Personal Information
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We may use your information for the following purposes:
                    </p>

                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To personalize your experience on our Platform.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To improve our products and services.card, etc.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To facilitate identity verification and
                            know-your-customer procedures.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To improve customer service.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To send you information and updates about our
                            products and services.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To send periodic communication such as newsletters
                            and promotional offers.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To contact you via email, phone, or SMS for
                            information related to the product you have opted
                            for.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            To fulfill our obligations under agreements with
                            third parties and comply with legal obligations.
                          </span>
                        </li>
                      </ul>
                    </div>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Information Sharing and Disclosure
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We may share your information without obtaining your prior
                      consent in the following circumstances:
                    </p>

                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            When required by law or by any court or governmental
                            agency
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            {" "}
                            In case Navnit Insurance is acquired by or merged
                            with another company.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            {" "}
                            To our partners, affiliates, employees, consultants,
                            contractors, and service providers who are bound by
                            contractual obligations to keep your information
                            confidential.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            For purposes such as detecting identity theft,
                            fraud, and other illegal acts.
                          </span>
                        </li>
                        <p
                          class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                          align="justify"
                        ></p>
                      </ul>
                    </div>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Cookies and Log Files
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We use cookies and log files to track user preferences,
                      improve site functionality, and analyze trends. Cookies do
                      not contain personally identifiable information. Log files
                      include IP addresses, browser type, and timestamps, which
                      help us understand user movements and improve our
                      services.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Security
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We employ appropriate technical and organizational
                      security measures to protect your information against
                      unauthorized access, alteration, disclosure, or
                      destruction. However, no method of transmission over the
                      internet is 100% secure, and we cannot guarantee absolute
                      security.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Email Opt-Out
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      If you no longer wish to receive marketing information
                      from us, please email your request to
                      operation@navnitinsurance.com. It may take up to 10 days
                      to process your request.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Retention of Personal Information
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We will retain your personal information as long as
                      necessary for the purposes described above and to comply
                      with legal obligations.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Changes to this Privacy Policy
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Navnit Insurance reserves the right to change this Privacy
                      Policy from time to time. We will notify you of any
                      changes by posting the updated policy on our website. We
                      encourage you to review this policy periodically.
                    </p>
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Links to Other Websites
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Our Platform may contain links to other websites. Navnit
                      Insurance is not responsible for the privacy practices of
                      these websites. We encourage you to read their privacy
                      policies when you visit them.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
