import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import career from "../../components/style/assets/img/insurance/career.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Career() {
  return (
    <React.Fragment>
      <>
        <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full">
              <div class="w-full px-[12px]">
                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                      Carrer
                    </h2>
                  </div>
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                      <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                        <a
                          href="index.html"
                          class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                        >
                          Home
                        </a>
                      </li>
                      <li class="text-[14px] font-normal px-[5px]">
                        <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                      </li>
                      <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                        Career
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-blog-details py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap mb-[-24px]">
              <div class="min-[1200px]:w-[100%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                <div
                  class="bb-blog-details-contact"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  {/* <div class="inner-blog-details-image mb-[24px]">
                                        <img src={career} alt="details-one" class="w-full rounded-[15px]" />
                                    </div> */}
                  <div class="inner-blog-details-image mb-[24px] flex">
                    <div className="w-[45%]">
                      <img
                        src={career}
                        alt="details-one"
                        class="w-full rounded-[15px]"
                      />
                    </div>
                    <div class="min-[1200px]:w-[55%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                      <div class="bb-blog-sidebar mb-[-24px]">
                        <div
                          class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div class="blog-sidebar-title mb-[20px]">
                            <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px] text-left">
                              Get In Touch
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner-blog-details-contact mb-[30px] text-left">
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Career
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]"
                      align="justify"
                    >
                      <b>
                        "Join Policies 365: Protecting Futures, Empowering
                        Careers
                        <br />
                        <br />
                        Become part of India's leading insurance experts!
                        Policies 365 offers:
                      </b>
                    </p>
                    <br />

                    <ul>
                      <li>* Dynamic work environment</li>
                      <li>* Professional growth and training</li>
                      <li>* Competitive commissions and incentives</li>
                      <li>* Opportunities for advancement</li>
                    </ul>
                    <br />
                    <br />

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Current Openings:
                    </h3>
                    <br />

                    <ul>
                      <li>* Insurance Advisors</li>
                      <li>* Sales Managers</li>
                      <li>* Operations Specialists</li>
                    </ul>

                    <h3>Apply Now</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
