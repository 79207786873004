import React from 'react';


const InputFieldCheckBox = ({ formikFieldName, formik, label, checkedValue, disabledValue }) => {
    return (

        <div className="checkbox-wrap">
            <input
            disabled={disabledValue}
                id="wp-comment-cookies-consent"
                name="wp-comment-cookies-consent"
                type="checkbox"
                value={true}
                {...formik?.getFieldProps(formikFieldName)}
                checked={typeof checkedValue === 'boolean' ? checkedValue : formik?.values[formikFieldName]}
            />
            <label className='label-text'>{label}</label>
        </div>
    );
}



export default InputFieldCheckBox
