import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import security from "../../components/style/assets/img/insurance/security.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Security() {
  return (
    <React.Fragment>
      <>
        <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full">
              <div class="w-full px-[12px]">
                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                      Security
                    </h2>
                  </div>
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                      <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                        <a
                          href="index.html"
                          class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                        >
                          Home
                        </a>
                      </li>
                      <li class="text-[14px] font-normal px-[5px]">
                        <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                      </li>
                      <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                        Security
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-blog-details py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap mb-[-24px]">
              <div class="min-[1200px]:w-[100%] min-[992px]:w-[58.33%] w-full px-[12px] mb-[24px]">
                <div
                  class="bb-blog-details-contact"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  {/* <div class="inner-blog-details-image mb-[24px]">
                                        <img src={security} alt="details-one" class="w-full rounded-[15px]" />
                                    </div> */}

                  <div class="inner-blog-details-image mb-[24px] flex">
                    <div className="w-[45%]">
                      <img
                        src={security}
                        alt="details-one"
                        class="w-full rounded-[15px]"
                      />
                    </div>
                    <div class="min-[1200px]:w-[55%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                      <div class="bb-blog-sidebar mb-[-24px]">
                        <div
                          class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div class="blog-sidebar-title mb-[20px]">
                            <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px] text-left">
                              Get In Touch
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner-blog-details-contact mb-[30px] text-left">
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Security
                    </h3>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Introduction
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Policies 365 is dedicated to maintaining the security and
                      integrity of our systems and protecting our customers'
                      data. We recognize the importance of information security
                      and take proactive measures to safeguard our
                      infrastructure and user information.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Commitment to Security
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      At Policies 365, the safety and security of customer
                      information are of utmost importance. We have implemented
                      robust security measures and protocols to ensure the
                      confidentiality, integrity, and availability of data. Our
                      team is committed to identifying and addressing all
                      security issues in a responsible and timely manner.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Reporting Security Issues
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      If you are an information security expert and have
                      identified a security-related issue on Navnit Insurance’s
                      website or mobile application, we encourage you to
                      disclose it to us conscientiously. By doing so, you help
                      us enhance the security of our platform and protect our
                      community.
                    </p>
                  </div>
                </div>
              </div>

           
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
