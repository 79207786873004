import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { paymentConstants } from "../../mockDataFolder/MockData";

export default function Contact() {
  var contactForm = {
    name: null,
    mobile: null,
    email: null,
    serviceType: paymentConstants.contactForm.serviceTypes.COMPLAINT,
    serviceOn: paymentConstants.contactForm.serviceOn.CAR,
    body: null,
  };

  // Function to retrieve the contact form data upon submission
  const submitContactsForm = () => {
    return contactForm;
  }

  return (
    <React.Fragment>
     
     <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
                    <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                        <div class="flex flex-wrap w-full">
                            <div class="w-full px-[12px]">
                                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                                    <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                                        <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">Contact</h2>
                                    </div>
                                    <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                                        <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                                            <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]"><a href="index.html" class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline">Home</a></li>
                                            <li class="text-[14px] font-normal px-[5px]"><i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i></li>
                                            <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">Contact</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-contact py-[50px] max-[1199px]:py-[35px]">
        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full mb-[-24px]">
                <div class="w-full px-[12px]">
                    <div class="section-title mb-[20px] pb-[20px] relative flex flex-col items-center text-center max-[991px]:pb-[0]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                        <div class="section-detail max-[991px]:mb-[12px]">
                            <h2 class="bb-title font-quicksand mb-[0] p-[0] text-[25px] font-bold text-[#3d4750] relative inline capitalize leading-[1] tracking-[0.03rem] max-[767px]:text-[23px]">Get In <span class="text-[#6c7fd8]">Touch</span></h2>
                            <p class="font-Poppins max-w-[400px] mt-[10px] text-[14px] text-[#686e7d] leading-[18px] font-light tracking-[0.03rem] max-[991px]:mx-[auto]">Connecting you to the best insurance options</p>
                        </div>
                    </div>
                </div>
                <div class="min-[992px]:w-[50%] w-full px-[12px] mb-[24px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                    <div class="bb-contact-form border-[1px] border-solid border-[#eee] rounded-[20px] p-[30px]">
                    <Form
                className="form-bg p-3"
                name="contactForm"
                onSubmit={submitContactsForm()}
              >
                <Row>
                  <Col md={12} sm={12}>
                    <FloatingLabel
                      controlId="name"
                      label="Name*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Name*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={12} sm={12}>
                    <FloatingLabel
                      controlId="mobile"
                      label="Mobile*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Mobile*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={12} sm={12}>
                    <FloatingLabel
                      controlId="email"
                      label="Email*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={12}>
                    <Row>
                      <Col sm={12} lg={12}>
                        <InputGroup className="mb-3 mt-3 w-100">
                          <InputGroup.Text>Service Type</InputGroup.Text>
                          <Dropdown>
                            <Dropdown.Toggle variant="success">
                              Complaint
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" value="query">
                                Query
                              </Dropdown.Item>
                              <Dropdown.Item href="#">Complaint</Dropdown.Item>
                              <Dropdown.Item href="#">Request</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col>

                      <Col sm={12} lg={12}>
                        <InputGroup className="mb-3 w-100">
                          <InputGroup.Text id="">Service On</InputGroup.Text>
                          <Dropdown>
                            <Dropdown.Toggle variant="success">
                              Buy Car Insurance
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" value="query">
                                Buy Car Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Two-Wheeler Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Life Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Health Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">Others</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} lg={12}>
                    <Form.Control
                      className="form form-control h-6rem"
                      as="textarea"
                      placeholder="Feedback / Complaint / Query"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} lg={12}>
                    <Button className="w-25 mt-4 primary-btn btn btn-primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
                    </div>
                </div>
                <div class="min-[992px]:w-[50%] w-full px-[12px] mb-[24px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">

<div class="sidebar__single sidebar__tags text-left">
           <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[25px] font-bold text-[#3d4750] max-[575px]:text-[20px]">Contact Details</h3>

           <strong> Call </strong>
           <p class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]" align="justify"><a href="tel:022-62526252"> 022-62526252</a></p>


           <strong>Email</strong>
           <p class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]" align="justify"><a href="mailto:contact@policies365.com">contact@policies365.com</a></p>    

           <strong>Address</strong>
           <p class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]" align="justify">172, Solitaire Corporate Park,<br /> Building No:1, 7th Floor, Andheri Ghatkopar Link,<br />   Andheri East, Mumbai, Maharashtra 400093</p><br />  




<div class="bb-contact-maps sticky top-[0]">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.848031444854!2d72.85602782520627!3d19.114321632098104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c831276ef31b%3A0x23146c3af5475771!2spolicies365.com!5e0!3m2!1sen!2sin!4v1729758932710!5m2!1sen!2sin" width="100%" height="250" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
</div>
</div>
                </div>
                </div>
                </section>

      {/* <div className="contact-bg">
        <div className="contact-container">
          <div className="w-100 m-2">
            <h1 className="page-heading">Leave us a message!</h1>

            <div className="contact-form-container">
              <Form
                className="form-bg p-3"
                name="contactForm"
                onSubmit={submitContactsForm()}
              >
                <Row>
                  <Col md={4} sm={4}>
                    <FloatingLabel
                      controlId="name"
                      label="Name*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Name*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={4} sm={4}>
                    <FloatingLabel
                      controlId="mobile"
                      label="Mobile*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Mobile*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={4} sm={4}>
                    <FloatingLabel
                      controlId="email"
                      label="Email*"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email*"
                        className="floating-input"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} lg={6}>
                    <Row>
                      <Col sm={12} lg={12}>
                        <InputGroup className="mb-3 w-100">
                          <InputGroup.Text>Service Type</InputGroup.Text>
                          <Dropdown>
                            <Dropdown.Toggle variant="success">
                              Complaint
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" value="query">
                                Query
                              </Dropdown.Item>
                              <Dropdown.Item href="#">Complaint</Dropdown.Item>
                              <Dropdown.Item href="#">Request</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col>

                      <Col sm={12} lg={12}>
                        <InputGroup className="mb-3 w-100">
                          <InputGroup.Text id="">Service On</InputGroup.Text>
                          <Dropdown>
                            <Dropdown.Toggle variant="success">
                              Buy Car Insurance
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" value="query">
                                Buy Car Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Two-Wheeler Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Life Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Buy Health Insurance
                              </Dropdown.Item>
                              <Dropdown.Item href="#">Others</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6} lg={6}>
                    <Form.Control
                      className="form form-control h-6rem"
                      as="textarea"
                      placeholder="Feedback / Complaint / Query"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} lg={12}>
                    <Button className="w-25" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
}
