import React from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import terms from "../../components/style/assets/img/insurance/terms-and-conditions.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TermsAndConditions() {
  return (
    <React.Fragment>
      <>
        <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap w-full">
              <div class="w-full px-[12px]">
                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                      Terms and Conditions
                    </h2>
                  </div>
                  <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                    <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                      <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                        <a
                          href="index.html"
                          class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                        >
                          Home
                        </a>
                      </li>
                      <li class="text-[14px] font-normal px-[5px]">
                        <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                      </li>
                      <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                        Terms and Conditions
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-blog-details py-[50px] max-[1199px]:py-[35px]">
          <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
            <div class="flex flex-wrap mb-[-24px]">
              <div class="min-[1200px]:w-[100%] min-[992px]:w-[58.33%] w-full px-[12px] mb-[24px]">
                <div
                  class="bb-blog-details-contact"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <div class="inner-blog-details-image mb-[24px] flex">
                    <div className="w-[45%]">
                      <img
                        src={terms}
                        alt="details-one"
                        class="w-full rounded-[15px]"
                      />
                    </div>
                    <div class="min-[1200px]:w-[55%] min-[992px]:w-[41.66666667%] w-full px-[12px] mb-[24px]">
                      <div class="bb-blog-sidebar mb-[-24px]">
                        <div
                          class="blog-inner-contact p-[30px] border-[1px] border-solid border-[#eee] mb-[24px] rounded-[20px] max-[575px]:p-[15px]"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div class="blog-sidebar-title mb-[20px]">
                            <h4 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[18px] text-left">
                              Get In Touch
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="inner-blog-details-image mb-[24px]">
                                        <img src={terms} alt="details-one" class="w-full rounded-[15px]" />
                                    </div> */}
                  <div class="inner-blog-details-contact mb-[30px] text-left">
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[30px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Terms and Conditions
                    </h3>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      Introduction
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Policies 365. By accessing and using our website, mobile
                      applications, and other online services (collectively
                      referred to as the "Platform"), you agree to comply with
                      and be bound by these Terms and Conditions ("Terms").
                      Please read them carefully.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      1. Acceptance of Terms
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      By using the Platform, you agree to these Terms, our
                      Privacy Policy, and any other policies or guidelines
                      posted on the Platform. If you do not agree with any part
                      of these Terms, you must not use the Platform.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      2. Eligibility
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      You must be at least 18 years of age to use the Platform.
                      By using the Platform, you represent and warrant that you
                      meet this age requirement.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      3. Use of the Platform
                    </h3>

                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Permitted Use: You may use the Platform for lawful
                            purposes and in accordance with these Terms.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Prohibited Use: You agree not to use the Platform:
                          </span>
                        </li>

                        <ul class="my-[-8px] pl-[18px]">
                          <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                            <span class="font-Poppins text-[#777] text-[14px]">
                              In any way that violates any applicable local,
                              state, national, or international law or
                              regulation.
                            </span>
                          </li>
                          <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                            <span class="font-Poppins text-[#777] text-[14px]">
                              To exploit, harm, or attempt to exploit or harm
                              minors in any way.
                            </span>
                          </li>
                          <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                            <span class="font-Poppins text-[#777] text-[14px]">
                              {" "}
                              To transmit any advertising or promotional
                              material without our prior written consent.
                            </span>
                          </li>
                          <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                            <span class="font-Poppins text-[#777] text-[14px]">
                              To impersonate or attempt to impersonate Navnit
                              Insurance, a Navnit Insurance employee, another
                              user, or any other person or entity.
                            </span>
                          </li>
                        </ul>
                      </ul>
                    </div>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      4. Account Registration
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      To access certain features of the Platform, you may need
                      to register for an account. You agree to:
                    </p>
                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Provide accurate, current, and complete information
                            during the registration process..
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Maintain and promptly update your account
                            information to keep it accurate, current, and
                            complete
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Maintain the security and confidentiality of your
                            login credentials.
                          </span>
                        </li>
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Notify us immediately of any unauthorized use of
                            your account.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      5. Intellectual Property Rights
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      To access certain features of the Platform, you may need
                      to register for an account. You agree to:
                    </p>
                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span
                            class="font-Poppins text-[#777] text-[14px]"
                            align="justify"
                          >
                            Ownership: The Platform and its entire contents,
                            features, and functionality, including but not
                            limited to all information, software, text,
                            displays, images, video, and audio, are owned by
                            Navnit Insurance, its licensors, or other providers
                            of such material and are protected by Indian and
                            international copyright, trademark, patent, trade
                            secret, and other intellectual property or
                            proprietary rights laws.
                          </span>
                        </li>

                        <li
                          class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc"
                          align="justify"
                        >
                          <span class="font-Poppins text-[#777] text-[14px]">
                            License: We grant you a limited, non-exclusive,
                            non-transferable, and revocable license to access
                            and use the Platform for personal, non-commercial
                            use only. This license is subject to your compliance
                            with these Terms.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      6. Limitation of Liability
                    </h3>
                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      To the fullest extent permitted by law, Navnit Insurance,
                      its affiliates, and their respective officers, directors,
                      employees, agents, service providers, contractors,
                      licensors, licensees, suppliers, and successors in
                      interest shall not be liable for any indirect, incidental,
                      special, consequential, or punitive damages, including but
                      not limited to loss of profits, data, use, goodwill, or
                      other intangible losses, resulting from:
                    </p>
                    <div class="bb-single-list mb-[30px]">
                      <ul class="my-[-8px] pl-[18px]">
                        <li class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc">
                          <span
                            class="font-Poppins text-[#777] text-[14px]"
                            align="justify"
                          >
                            Your use of or inability to use the Platform.
                          </span>
                        </li>

                        <li
                          class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc"
                          align="justify"
                        >
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Any conduct or content of any third party on the
                            Platform.
                          </span>
                        </li>

                        <li
                          class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc"
                          align="justify"
                        >
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Any content obtained from the Platform.
                          </span>
                        </li>

                        <li
                          class="my-[8px] font-Poppins text-[14px] font-light leading-[28px] tracking-[0.03rem] text-[#777] list-disc"
                          align="justify"
                        >
                          <span class="font-Poppins text-[#777] text-[14px]">
                            Unauthorized access, use, or alteration of your
                            transmissions or content.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      7. Indemnification
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      To the fullest extent permitted by law, Navnit Insurance,
                      its affiliates, and their respective officers, directors,
                      employees, agents, service providers, contractors,
                      licensors, licensees, suppliers, and successors in
                      interest shall not be liable for any indirect, incidental,
                      special, consequential, or punitive damages, including but
                      not limited to loss of profits, data, use, goodwill, or
                      other intangible losses, resulting from:
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      8. Governing Law and Jurisdiction
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      These Terms shall be governed by and construed in
                      accordance with the laws of India, without regard to its
                      conflict of law principles. Any disputes arising out of or
                      in connection with these Terms shall be subject to the
                      exclusive jurisdiction of the courts located in Mumbai,
                      India.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      9. Changes to Terms and Conditions
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      Policies 365 reserves the right to modify these Terms at
                      any time. We will notify you of any changes by posting the
                      new Terms on the Platform. Your continued use of the
                      Platform after the posting of the revised Terms means that
                      you accept and agree to the changes.
                    </p>

                    <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]">
                      10. Termination
                    </h3>

                    <p
                      class="mb-[16px] font-Poppins text-[15px] text-[#686e7d] font-light leading-[30px] tracking-[0.03rem]"
                      align="justify"
                    >
                      We may terminate or suspend your access to the Platform
                      immediately, without prior notice or liability, for any
                      reason whatsoever, including without limitation if you
                      breach these Terms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
