import React from "react";
import {
    Button,
    Col,
    Dropdown,
    FloatingLabel,
    Form,
    InputGroup,
    Row,
} from "react-bootstrap";
import { paymentConstants } from "../../mockDataFolder/MockData";
import aboutUS from "../../components/style/assets/img/insurance/about-us.jpg";
import aboutUSOne from "../../components/style/assets/img/insurance/about-us-1.jpg";
import teamOne from "../../components/style/assets/img/team/mohammad-kantawala.jpg";
import teamTwo from "../../components/style/assets/img/team/pasarthi.jpg";
import teamThree from "../../components/style/assets/img/team/kiran-khanvilkar.jpg";
import teamFour from "../../components/style/assets/img/team/sanyogita-sawant.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function About() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                },
            },
        ]
    };


    return (
        <React.Fragment>
            <>

                <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
                    <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                        <div class="flex flex-wrap w-full">
                            <div class="w-full px-[12px]">
                                <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                                    <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                                        <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">About Us</h2>
                                    </div>
                                    <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                                        <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                                            <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]"><a href="index.html" class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline">Home</a></li>
                                            <li class="text-[14px] font-normal px-[5px]"><i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i></li>
                                            <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">About Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-about py-[50px] max-[1199px]:py-[35px]">
                    <div class="flex flex-wrap justify-between items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                        <div class="flex flex-wrap w-full mb-[-24px]">
                            <div class="min-[992px]:w-[50%] w-full px-[12px] mb-[24px]">
                                <div class="bb-about-img sticky top-[0]">
                                    <img src={aboutUS} alt="about-one" class="w-full" />
                                </div>
                            </div>
                            <div class="min-[992px]:w-[50%] w-full mb-[24px]">
                                <div class="bb-about-contact h-full flex flex-col justify-center">
                                    <div class="section-title pb-[12px] px-[12px] flex justify-start max-[991px]:flex-col max-[991px]:justify-center max-[991px]:text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                        <div class="section-detail max-[991px]:mb-[12px]">
                                            <h2 class="bb-title font-quicksand tracking-[0.03rem] mb-[0] p-[0] text-[25px] font-bold text-[#3d4750] inline capitalize leading-[1] max-[767px]:text-[23px]">About us </h2>
                                        </div>
                                    </div>
                                    <div class="about-inner-contact px-[12px] mb-[14px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">Navnit Insurance Broking Pvt. Ltd. (NIBPL), is part of the established Navnit Group, has been providing insurance broking services since 2012. Navnit Group with 40+ years of excellence in the mobility sector, have established itself as a trusted name in India. NIBPL caters to more than 2 lakh customers across India, offering personalized insurance solutions that safeguard individuals, families, and businesses.
                                        </p>
                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px] text-left">Our Journey</h3>
                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">We began with a mission to provide transparent and tailored insurance solutions. Today, we are associated with 40+ trusted partners, allowing us to offer a comprehensive range of products across life, health, motor, and business insurance. Our strong foundation, coupled with a customer-first approach, ensures that we meet the unique needs of every client.
                                        </p>


                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px] text-left"> Our Vision:</h3>
                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">Our vision is to be the leading insurance broking firm in India by blending our trust with customers, adopting innovations and be part of the countries vision of insurance for all by 2047. We strive to simplify insurance, distribution process and serve the customer at their doorsteps, providing peace of mind and securing their future.</p>


                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px] text-left"> Our Milestones:</h3>
                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">
                                            Great Place to Work Certified: At NIBPL, we pride ourselves on fostering a positive, dynamic work environment that has earned us the Great Place to Work certification.<br /><br />

                                            2 Lakh+ Happy Customers: Our commitment to excellence has helped us serve and protect over 200,000 customers, and this number continues to grow as we expand our footprint across India.<br /><br />

                                            40+ Insurance Partners: With a robust network of partners, we provide comprehensive insurance coverage, offering the best products from the leading insurance companies.<br /><br />

                                            Multiple Channel of Distributions: We pride ourselves in approaching the customer through a channel which serves them best. Accordingly we have wave aggregator platform - Policies365.com , Point of Sale Personnel, Call Center, Relationship Managers and other modes of feet on the street like petrol pumps. </p>


                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px] text-left"> Our Values:</h3>
                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">Customer-Centricity: We put our customers at the center of everything we do.<br /><br />

                                            Transparency: We believe in providing clear and honest communication, ensuring that our clients understand their insurance choices fully.<br /><br />

                                            Innovation: We continuously evolve by embracing cutting-edge technology, making the insurance process seamless, efficient and simple to transact.<br /><br />

                                            Trust: We commit to be there for our clients at the time of distress and go all out to support and handhold during their claim process. Serving customers post sale is the hallmark of our promise. </p>



                                    </div>
                                </div>
                            </div>


                            <div class="min-[992px]:w-[50%] w-full mb-[24px]">
                                <div class="bb-about-contact h-full flex flex-col justify-center">
                                    <div class="section-title pb-[12px] px-[12px] flex justify-start max-[991px]:flex-col max-[991px]:justify-center max-[991px]:text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">

                                    </div>
                                    <div class="about-inner-contact px-[12px] mb-[14px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">

                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px]"> Our Promoters</h3>
                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">
                                            <b>Mr. Navnit Kachalia - Chairman</b><br />
                                            With over 50 years of leadership at the helm of the Navnit Group, Mr. Navnit Kachalia's vision has been instrumental in the growth and diversification of the group. Under his guidance, NIBPL has successfully established as a known player into the insurance sector, upholding the group’s core values of trust and customer satisfaction.<br /><br />

                                            <b>Mr. Ashish Kachalia - Director</b><br />
                                            Mr. Ashish Kachalia is a driving force behind NIBPL’s strategic decisions. His forward-thinking approach and hands-on involvement in daily operations have fueled the company’s expansion. He ensures that the business stays customer-focused while adopting cutting-edge solutions to meet modern-day insurance challenges.</p>



                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px] text-left"> Certified as a Great Place to Work</h3>


                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">At NIBPL, our commitment extends not only to our clients but also to our people. We are proud to be Great Place to Work certified, a testament to our culture of inclusivity, employee development, and a supportive work environment. This recognition reflects the efforts we put into building a workplace where our team can thrive and contribute to our mission.</p>
                                        <h3 class="sub-title font-quicksand tracking-[0.03rem] leading-[1.2] mb-[12px] text-[20px] font-bold text-[#3d4750] max-[575px]:text-[20px] text-left"> Conclusion</h3>

                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">With over 2 lakh satisfied customers, partnerships with 40+ leading insurers, and the support of the Navnit Group’s 40+ years of legacy, Navnit Insurance Broking continues to provide comprehensive insurance solutions that empower individuals and businesses alike. Our journey is rooted in trust, innovation, and a deep commitment to safeguarding the future of our clients.<br /><br />

                                            At NIBPL, we believe in making insurance simple, transparent, and accessible for everyone and our vision is aligned to be part of insurance for all by 2047.</p>



                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">We believe that our interaction with customers exceeds the selling of a product. We draw on our knowledge the sector to provide consultations, professional follow up and an attention to detail at every stage of the brand experience.</p>

                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">This is as true of our long standing mobility businesses as it is our new initiatives.</p>

                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">Our relationship with our customers lies at the heart of our business philosophy.</p>



                                    </div>


                                    <div class="bb-vendor-rows flex flex-wrap w-full mb-[-24px]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                                        <div class="min-[576px]:w-[33.33%] w-full px-[12px] mb-[24px]">
                                            <div class="bb-vendor-box p-[20px] bg-[#f8f8fb] border-[1px] border-solid border-[#eee] rounded-[20px]">
                                                <div class="bb-count mb-[12px] max-[1399px]:mb-[8px]">
                                                    <h5 class="counter font-quicksand tracking-[0.03rem] text-[32px] leading-[24px] text-[#3d4750] font-bold max-[1399px]:text-[28px] text-center">30K+</h5>
                                                </div>
                                                <div class="inner-text">
                                                    <p class="font-Poppins font-light tracking-[0.03rem] text-[14px] leading-[18px] text-[#686e7d] mb-[0] text-center">Insurance<br /> P.M</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="min-[576px]:w-[33.33%] w-full px-[12px] mb-[24px]">
                                            <div class="bb-vendor-box p-[20px] bg-[#f8f8fb] border-[1px] border-solid border-[#eee] rounded-[20px]">
                                                <div class="bb-count mb-[12px] max-[1399px]:mb-[8px]">
                                                    <h5 class="counter font-quicksand tracking-[0.03rem] text-[32px] leading-[24px] text-[#3d4750] font-bold max-[1399px]:text-[28px] text-center">3L+</h5>
                                                </div>
                                                <div class="inner-text">
                                                    <p class="font-Poppins font-light tracking-[0.03rem] text-[14px] leading-[18px] text-[#686e7d] mb-[0] text-center">Satisfied<br /> Customers</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="min-[576px]:w-[33.33%] w-full px-[12px] mb-[24px]">
                                            <div class="bb-vendor-box p-[20px] bg-[#f8f8fb] border-[1px] border-solid border-[#eee] rounded-[20px]">
                                                <div class="bb-count mb-[12px] max-[1399px]:mb-[8px]">
                                                    <h5 class="counter font-quicksand tracking-[0.03rem] text-[32px] leading-[24px] text-[#3d4750] font-bold max-[1399px]:text-[28px] text-center">90%</h5>
                                                </div>
                                                <div class="inner-text">
                                                    <p class="font-Poppins font-light tracking-[0.03rem] text-[14px] leading-[18px] text-[#686e7d] mb-[0] text-center">Our <br />sucess rate </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="min-[992px]:w-[50%] w-full px-[12px] mb-[24px]">
                                <div class="bb-about-img sticky top-[0]">
                                    <img src={aboutUSOne} alt="about-one" class="w-full" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-team py-[50px] max-[1199px]:py-[35px]">
                    <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
                        <div class="flex flex-wrap w-full">
                            <div class="w-full px-[12px]">
                                <div class="section-title text-center mb-[20px] pb-[20px] z-[5] relative flex justify-center max-[991px]:pb-[0]" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                    <div class="section-detail max-[991px]:mb-[12px]">
                                        <h2 class="bb-title font-quicksand mb-[0] p-[0] text-[35px] font-bold text-[#3d4750] relative inline capitalize leading-[1] tracking-[0.03rem] max-[767px]:text-[23px]">Our <span class="text-[#6c7fd8]">Leadership Team</span></h2><br /><br />



                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">NIBPL’s success is driven by a talented core team that combines expertise in insurance with a deep commitment to client satisfaction. Supported by a broader team of over 200 dedicated professionals and growing, our core team works closely to meet the needs of our growing customer base. As part of the larger 3,000+ strong workforce within the Navnit Group, our efforts are further bolstered by the group’s decades of experience and cross-industry knowledge.</p>


                                        <p class="font-Poppins mb-[16px] text-[14px] text-[#686e7d] font-light leading-[28px] tracking-[0.03rem]" align="justify">
                                            Mr. Swami Swaminathan - Vice Chairman and Director<br />
                                            Mr. Parthsarthi Kumar - Chief Executive Officer (CEO)<br />
                                            Mr. Mohammad Kantawala - Head of Department (Sales & Operations)<br />
                                            Mr. Manoj Juriasinghani - CTO<br />
                                            Mr. Kiran Khanvillkar - Policy Officer (PO)<br />
                                            Mrs. Sanyogita Sawant - Compliance Officer</p>

                                    </div>
                                </div>
                            </div>
                            <div class="w-full px-[12px]">
                                <Slider {...settings} className="testimonials-slider bb-team slider-container">
                                    <div>
                                        <div class="bb-team-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                            <div class="bb-team-img mb-[20px] relative h-full flex items-center overflow-hidden">
                                                <img src={teamOne} alt="team-1" class="w-full rounded-[20px]" />
                                            </div>
                                            <div class="bb-team-contact text-center">
                                                <h5 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[18px] font-bold text-[#3d4750]">Mohammad Kantawala</h5>
                                                <p class="font-Poppins font-light leading-[28px] tracking-[0.03rem] text-[15px] text-[#686e7d]">Head of Department</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div class="bb-team-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                                                <div class="bb-team-img mb-[20px] relative h-full flex items-center overflow-hidden">
                                                    <img src={teamTwo} alt="team-2" class="w-full rounded-[20px]" />
                                                </div>
                                                <div class="bb-team-contact text-center">
                                                    <h5 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[18px] font-bold text-[#3d4750]">Parthsarthi Kumar</h5>
                                                    <p class="font-Poppins font-light leading-[28px] tracking-[0.03rem] text-[15px] text-[#686e7d]">CEO</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <div class="bb-team-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
                                            <div class="bb-team-img mb-[20px] relative h-full flex items-center overflow-hidden">
                                                <img src={teamThree} alt="team-4" class="w-full rounded-[20px]" />
                                            </div>
                                            <div class="bb-team-contact text-center">
                                                <h5 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[18px] font-bold text-[#3d4750]">Kiran Khanvilkar</h5>
                                                <p class="font-Poppins font-light leading-[28px] tracking-[0.03rem] text-[15px] text-[#686e7d]">PO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bb-team-box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                                        <div class="bb-team-img mb-[20px] relative h-full flex items-center overflow-hidden">
                                            <img src={teamFour} alt="team-3" class="w-full rounded-[20px]" />
                                        </div>
                                        <div class="bb-team-contact text-center">
                                            <h5 class="font-quicksand tracking-[0.03rem] leading-[1.2] text-[18px] font-bold text-[#3d4750]">Sanyogita Sawant</h5>
                                            <p class="font-Poppins font-light leading-[28px] tracking-[0.03rem] text-[15px] text-[#686e7d]">Complaince Officer</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </React.Fragment>
    );
}
