import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import AuthService from "../../services/auth-service";
import { useHistory } from "react-router-dom";
import UserContext from "../../components/Context/UserContext";
import UseFormContext from "../../context/UseFormContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login(props) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const formContext = UseFormContext();
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const [data, setData] = useState({
    username: "",
    password: "",
    loginAs: "USER",
  });
  const [formErrors, setFormErrors] = useState({
    username: false,
    password: false,
    loginAs: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value === "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formErrors).some((error) => error)) {
      // If any field is empty, do not proceed
      return;
    }

    try {
      const userData = await AuthService.login(
        data.username,
        data.password,
        data.loginAs
      );
      setUser(userData.loginAs);
      sessionStorage.setItem("isAdmin", "yes");
      formContext.setloginType("yes");
      //history.push("/");
    } catch (error) {
      alert(
        "Please check your email & password. If you are unable to log in, kindly contact the administrator for assistance."
      );
      console.error("Login failed:", error);
    }
  };

  return (
    
    <>
         <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
          <div class="flex flex-wrap w-full">
            <div class="w-full px-[12px]">
              <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
              <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                            <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">Login</h2>
                        </div>
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                    <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]"><a href="index.html" class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline">Home</a></li>
                    <li class="text-[14px] font-normal px-[5px]"><i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i></li>
                    <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">Login</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <div className="signin-wrap py-[50px] max-[1199px]:py-[35px]">
      <div className="inner-sign-in">

      <div class="w-full px-[12px]">
                    <div class="section-title mb-[20px] pb-[20px] relative flex flex-col items-center text-center max-[991px]:pb-[0] aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                        <div class="section-detail max-[991px]:mb-[12px]">
                            <h2 class="bb-title font-quicksand mb-[0] p-[0] text-[25px] font-bold text-[#3d4750] relative inline capitalize leading-[1] tracking-[0.03rem] max-[767px]:text-[23px]">Log <span class="text-[#6c7fd8]">In</span></h2>
                            <p class="font-Poppins max-w-[400px] mt-[10px] text-[14px] text-[#686e7d] leading-[18px] font-light tracking-[0.03rem] max-[991px]:mx-[auto]">Best place to buy and sell digital products</p>
                        </div>
                    </div>
                </div>

      <div class="w-full px-[12px]">
                    <div class="bb-login-contact max-w-[500px] m-[auto] border-[1px] border-solid border-[#eee] p-[30px] rounded-[20px] aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">

        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <div className="form-group mt-3">
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="m-0 mb-4"
              >
                <Form.Control
                  type="email"
                  name="username"
                  className="floating-input"
                  onChange={handleInputChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Password*" className="m-0  mb-4">
                <Form.Control
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="floating-input"
                  onChange={handleInputChange}
                  required
                />
                <span
                  className="position-absolute end-0 top-0 mt-2 me-3"
                  onClick={toggleShowPassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </FloatingLabel>

              {/* 
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  name="password"
                  className="floating-input"
                  onChange={handleInputChange}
                  required
                />
              </FloatingLabel> */}
            </div>
            <div className="inner-sign-in">
              <button
                id="btn-login"
                type="submit"
                className="primary-btn"
                disabled={Object.values(formErrors).some((error) => error)}
              >
                Submit
              </button>
            </div>
            <p className="forgot-password text-center mt-3">
              New Here ? <a href="onBoardingPage"> Register As a POSP</a>
            </p>

            {/* <p className="forgot-password text-right mt-2">
              <a href="resetPassword">Forgot password?</a>
            </p> */}
          </div>
        </form>
</div>
</div>
      </div>
    </div>
</>
  );
}
