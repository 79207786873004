import axios from "axios"
import { convertDate, gateage } from "../../components/commonModules/CommonCode";
const { format, parseISO } = require('date-fns');
export const rtoBaseUrl = process.env.REACT_APP_RTO_API_URL
export const rtoLoginUrl = `login`
export const projectBaseurl = process.env.REACT_APP_BASE_URL
export const getCitiesUrl = `/cxf/configmanager/config/getconfigdata`
export const createQote = `/cxf/authrestservices/integrate/invoke`
export const getRtodetails = '/cxf/authrestservices/integrate/invoke'
export const getpolicyName = '/cxf/Insuranceservice/request/integrate/invoke'
export const kycBaseurl = `/cxf/kycservice/request/integrate/invoke`
export const addressUrl = `https://api.postalpincode.in/pincode/`
export const mobileNumberurl = `/cxf/Otpservice/request/integrate/invoke`
 
// function kycEncrypt(request) {
//   const payload = {
//     payload: request,
//   };
//   return axios.post("https://api.bagicuat.bajajallianz.com/csckyc/utility/encryptJson", payload);
// }
 
// function kycDecrypt(request) {
//   const payload = {
//     payload: request,
//   };
//   return axios.post("https://api.bagicuat.bajajallianz.com/csckyc/utility/decryptJson", payload);
// }
 
export function bajajKycVerify(transactionId, values) {
  const request = {
    //prod
    "URL":"https://webapi.bajajallianz.com/csckyc/validateCkycDetails",
    //uat
    //  "URL":"https://api.bagicuat.bajajallianz.com/csckyc/validateCkycDetails",
    "carrierId":52,
    "request":{
      "dob": format(parseISO(values?.kycNomineDob), 'dd-MMM-yyyy'),
      "docNumber": values?.kycNominePanNumber,
      "fieldValue": transactionId,
      }
  };
  return axios.post(projectBaseurl + kycBaseurl, request);
}
 
export function bajajDocumentUploadForKyc(transactionId, request) {
  const requestBody = {
    "URL": "https://webapi.bajajallianz.com/csckyc/uploadKYCDocument",
    "carrierId": 52,
    "request": {
      "appType": "KYC_WS_BROKER",
      "fieldType": "PROPOSAL_NUMBER",
      "fieldValue": transactionId,
      "userId": "webservice@nibpl.com",
      "kycDocumentType": "POA",
      "kycDocumentCategory": request?.kycDocumentCategory,
      "documentNumber": request?.documentNumber,
      "documentExtension": request?.documentExtension,
      "documentArray": request?.documentArray,
    },
  };
  return axios.post(projectBaseurl + kycBaseurl, requestBody);
}