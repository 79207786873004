import ReactSelect, {
  ControlProps,
  GroupBase,
  OptionProps,
} from "react-select";

// export interface SelectProps {
//   options: Array<{ value: string; label: string }>;
//   onChange?: (newValue: any) => void;
//   onBlur?: () => void;
//   isSearchable?: boolean;
//   noOptionsMessage?: string;
//   placeholder?: string;
//   value?: any;
//   className?: string;
//   isMulti?: boolean;
//   isDisabled?: boolean;
// }

export function Select(props) {
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: `12px`,
      fontWeight: 400,
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      "&:hover": {
        backgroundColor:
          state.isSelected && state.isFocused
            ? `rgb(211, 22, 107)`
            : "rgb(222, 235, 255)",
      },
      backgroundColor: state.isSelected ? `rgb(211, 22, 107)` : "inherit",
      fontSize: `12px`,
      fontWeight: 400,
      color: "black",
      accentColor: "blue",
    }),
  };
  //console.log("props",props)

  return (
    <ReactSelect
      disabled={props.disabled ? props.disabled : false}
      classNamePrefix="react-select"
      onBlur={props.onBlur}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      className={props.className}
      options={props.options}
      placeholder={props.placeholder ? props.placeholder : "Search Here"}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      isDisabled={props.isDisabled}
      noOptionsMessage={() =>
        props.noOptionsMessage ? props.noOptionsMessage : "No Result Found"
      }
      styles={styles}
    />
  );
}

export default Select;
