import axios from "axios";
import { DateConvertFunction } from "../components/commonModules/CommonCode";
import { convertDate, gateage } from "../components/commonModules/CommonCode";
export const rtoBaseUrl = process.env.REACT_APP_RTO_API_URL;
export const rtoLoginUrl = `login`;
export const rtoUsername = process.env.REACT_APP_RTO_API_USERNAME;
export const rtoPassword = process.env.REACT_APP_RTO_API_PASSWORD;
export const projectBaseurl = process.env.REACT_APP_BASE_URL;
export const getCitiesUrl = `/cxf/configmanager/config/getconfigdata`;
export const createQote = `/cxf/authrestservices/integrate/invoke`;
export const getRtodetails = "/cxf/authrestservices/integrate/invoke";
export const getpolicyName = "/cxf/Insuranceservice/request/integrate/invoke";
export const kycBaseurl = `/cxf/kycservice/request/integrate/invoke`;
export const addressUrl = `https://api.postalpincode.in/pincode/`;
export const mobileNumberurl = `/cxf/Otpservice/request/integrate/invoke`;

// to login rto with username and password

export function rtoLogin() {
  const data = {
    request: {
      username: rtoUsername,
      password: rtoPassword,
    },

    URL: `${rtoBaseUrl}/login`,
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}
// to get data from rto about vechicle
export function rtoGetData(userId, id, searchParameter) {
  console.log("api called");
  const rtoLoginGetData = `${userId}/vehicleregistrations/?access_token=${id}`;
  const data = {
    request: {
      task: "detailedSearch",
      essentials: {
        vehicleNumber: searchParameter,
        signzyID: "AKYTSZY",
      },
    },

    URL: rtoBaseUrl + rtoLoginGetData,
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

// to get cities when popup appears eg mh-12pune
export function getCities(filterData, city) {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers": "Origin, Content-Type",
      "Access-Control-Allow-Credentials": true,
    },
  };
  return axios.get(
    projectBaseurl + getCitiesUrl + `?filter=${filterData}&q=${city}`
  );
}

// to create Quotes on bike  page
export function createQoteApi(values, idv = 0, prevQuoteId) {
  console.log("prevQuoteIdbike", prevQuoteId, "idv", idv);
  const currentDate = DateConvertFunction(new Date(), 16);
  const tenuarDate = DateConvertFunction(
    new Date(),
    17,
    Number(values?.policyTerms)
  );

  const planType =
    values?.insuranceFor === "new"
      ? "OD-TP-5"
      : values?.policyType + "-" + values?.policyTerms;
  let PreviousPolicyExpiryDate;
  if (values?.insuranceFor === "new") {
    PreviousPolicyExpiryDate = null;
  } else {
    PreviousPolicyExpiryDate = values?.expiryDate
      ? convertDate(values?.expiryDate, 1)
      : values?.previousPolicy;
  }
  let tpDates = {
    TPPolicyExpiryDate: values?.tpPolicyEndtDate
      ? convertDate(values?.tpPolicyEndtDate, 3)
      : null,
    TPPolicyStartDate: values?.tpPolicyStartDate
      ? convertDate(values?.tpPolicyStartDate, 3)
      : null,
    ODPolicyExpiryDate: PreviousPolicyExpiryDate,
  };
  if (values?.insuranceFor === "new") {
    tpDates.TPPolicyExpiryDate = null;
    tpDates.TPPolicyStartDate = null;
    tpDates.ODPolicyExpiryDate = null;
  }

  let registrationValidityDate = "";
  if(values?.insuranceFor === "new"){ 
    let newDate=parseInt(values.registrationYear)+15
    registrationValidityDate="01/01/"+newDate
  }
  else if (values?.insuranceFor === "renew") {
    let newDate = new Date(values.registrationYear);
    newDate.setFullYear(newDate.getFullYear() + 15);
    let day = newDate.getDate().toString().padStart(2, '0');
    let month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    let year = newDate.getFullYear();
    registrationValidityDate = `${day}/${month}/${year}`;
  }

  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "getBikeQuote",
    },
    body: {
      quoteParam: {
        ncb: Number(values?.noClaimBonus),
        ownedBy: values.vehicalRegType ? values.vehicalRegType : "Individual",
        policyType: values?.insuranceFor ? values?.insuranceFor : "renew",
        planType: planType,
        riders: values?.addOnCover ? values?.addOnCover : [],
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        vahanData: "demo",
      },
      vehicleInfo: {
        IDV: Number(idv),
        RTOCode: values?.registeredRto?.slice(0, 4).toUpperCase(),
        previousClaim: values?.insuranceClaim,
        make: values?.make,
        model: values?.model,
        variant: values?.varient,
        registrationPlace: values?.registeredRto,
        PreviousPolicyExpiryDate: PreviousPolicyExpiryDate,
        dateOfRegistration: convertDate(values?.registrationYear, 1),
        best_quote_id: idv > 0 ? prevQuoteId : "",
        TPPolicyExpiryDate: tpDates.TPPolicyExpiryDate,
        TPPolicyStartDate: tpDates.TPPolicyStartDate,
        ODPolicyExpiryDate: tpDates.ODPolicyExpiryDate,
        policyStartDate: currentDate,
        policyExpiryDate: tenuarDate,
        registrationValidityDate,
      },
      campaign_id: "ce9a676c-af21-d810-7f96-5c98bf17d9f6",
    },
  };
  if (sessionStorage.getItem("id")) {
    data.body.quoteParam["agentId"] = sessionStorage.getItem("id");
  }
  if (sessionStorage.getItem("agencyId")) {
    data.body.quoteParam["agencyId"] = sessionStorage.getItem("agencyId");
  }
  if (sessionStorage.getItem("branchId")) {
    data.body.quoteParam["branchId"] = sessionStorage.getItem("branchId");
  }
  // data.body.quoteParam["dealId"]="DL-3005/1485788"

  if(localStorage.getItem("dealId") && localStorage.getItem("dealId")!== null && localStorage.getItem("dealId")!== undefined){
    data.body.quoteParam["dealId"]= localStorage.getItem("dealId")
  }
  return axios.post(projectBaseurl + createQote, data);
}

// to create Quotes on bike and car page
export function createQoteApiCar(values, idv = 0, prevQuoteId) {
  console.log("prevQuoteIdcarrr", prevQuoteId);
  console.log("quote valuessssssssssssssss", values);
  const planType =
    values?.insuranceFor === "new" ? "OD-TP-3" : values?.policyType;
  let PreviousPolicyExpiryDate;
  if (values?.insuranceFor === "new") {
    PreviousPolicyExpiryDate = null;
  } else {
    PreviousPolicyExpiryDate = values?.expiryDate
      ? convertDate(values?.expiryDate, 1)
      : values?.previousPolicy;
  }
  let tpDates = {
    TPPolicyExpiryDate: values?.tpPolicyEndtDate
      ? convertDate(values?.tpPolicyEndtDate, 3)
      : null,
    TPPolicyStartDate: values?.tpPolicyStartDate
      ? convertDate(values?.tpPolicyStartDate, 3)
      : null,
    ODPolicyExpiryDate: PreviousPolicyExpiryDate,
  };
  if (values?.insuranceFor === "new") {
    tpDates.TPPolicyExpiryDate = null;
    tpDates.TPPolicyStartDate = null;
    tpDates.ODPolicyExpiryDate = null;
  }

  let registrationValidityDate = "";
  if(values?.insuranceFor === "new"){
    let newDate=parseInt(values.registrationYear)+15
    registrationValidityDate="01/01/"+newDate
  }
  else if (values?.insuranceFor === "renew") {
    let newDate = new Date(values.registrationYear);
    newDate.setFullYear(newDate.getFullYear() + 15);
    let day = newDate.getDate().toString().padStart(2, '0');
    let month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    let year = newDate.getFullYear();
    registrationValidityDate = `${day}/${month}/${year}`;
  }

  const data = {
    header: {
      messageId: "",
      source: "web",
      deviceId: "web",
      browser: "Chrome V - 120",
      transactionName: "carQuote",
    },
    body: {
      vehicleInfo: {
        IDV: Number(idv),
        PreviousPolicyExpiryDate: PreviousPolicyExpiryDate,
        RTOCode: values?.registeredRto?.slice(0, 4).toUpperCase(),
        dateOfRegistration: convertDate(values?.registrationYear, 1),
        idvOption: 1,
        previousClaim: values?.insuranceClaim,
        registrationPlace: values?.registeredRto,
        make: values?.make,
        model: values?.model,
        variant: values?.varient,
        fuel: values?.fuel,
        cubicCapacity: "",
        best_quote_id: idv > 0 ? prevQuoteId : "",
        TPPolicyExpiryDate: tpDates.TPPolicyExpiryDate,
        TPPolicyStartDate: tpDates.TPPolicyStartDate,
        ODPolicyExpiryDate: tpDates.ODPolicyExpiryDate,
        registrationValidityDate,
      },
      quoteParam: {
        ncb: Number(values?.noClaimBonus),
        ownedBy: values?.ownedBy ? values?.ownedBy : "Individual",
        policyType: values?.insuranceFor ? values?.insuranceFor : "renew",
        quoteType: "3",
        riders: values?.addOnCover ? values?.addOnCover : [],

        planType: planType,
        occupationId: "1",
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        vahanData: "demo",
      },
      requestSource: "web",
      messageId: "",
     
    },
  };
  if (sessionStorage.getItem("id")) {
    data.body.quoteParam["agentId"] = sessionStorage.getItem("id");
  }
  if (sessionStorage.getItem("agencyId")) {
    data.body.quoteParam["agencyId"] = sessionStorage.getItem("agencyId");
  }
  if (sessionStorage.getItem("branchId")) {
    data.body.quoteParam["branchId"] = sessionStorage.getItem("branchId");
  }
  // data.body.quoteParam["dealId"]="DL-3005/1485788"
  if(localStorage.getItem("dealId") && localStorage.getItem("dealId")!== null && localStorage.getItem("dealId")!== undefined){
    data.body.quoteParam["dealId"] = localStorage.getItem("dealId");
  }
  return axios.post(projectBaseurl + createQote, data);
}

// to get Quotes on Quotes page bike/car
export function getQoteApi(qname, messageId, QUOTE_ID, transactionName) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName:
        transactionName === "bike" ? "getBikeQuoteResult" : "getCarQuoteResult",
    },
    body: {
      qname: qname,
      messageId: messageId,
      QUOTE_ID: QUOTE_ID,
    },
  };

  return axios.post(projectBaseurl + createQote, data);
}

// to get list of all rtos
export function getRtoInfo() {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "getPopularRTO",
    },
    body: {
      popularRTOList: "Y",
      requestSource: "web",
      leadSource: { utm_campaign: "" },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// to get list of all riders
export function getRiderInfo() {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: "Bike",
      documentType: "Rider",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

//to create lead for policy
export function createLead(values, props) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V - 74",
      transactionName: "createLead",
    },
    body: {
      quoteParam: {
        insuranceType: 1,
        ncb: 25,
        owneredBy: "Individual",
        personAge: 40,
        policyType: "renew",
        zone: "ZoneA",
        customerpinCode: "",
        customerCity: "",
        customerState: "",
        documentType: "QuoteRequest",
        quoteType: 2,
        policyExpiredAge: 0.0547945205479452,
        userIdv: 0,
      },
      vehicleInfo: {
        IDV: 0,
        RTOCode: "MH01",
        name: "Hero MotoCorp",
        previousClaim: "false",
        previousPolicyInvoiceCoverStatus: false,
        previousPolicyZeroDepStatus: false,
        regYear: "2015",
        displayVehicle: "Hero MotoCorp Duet VX",
        registrationPlace: "MH-01 Mumbai Tardeo",
        variantId: "BikeVarientId-824",
        PreviousPolicyExpiryDate: "31/3/2020",
        previousPolicyExpired: "N",
        city: "MUMBAI",
        isCostal: "N",
        isEarthQuakeArea: "Y",
        state: "Maharashtra",
        dateOfRegistration: "01/07/2015",
        PreviousPolicyStartDate: "01/04/2019",
        idvOption: 1,
      },
      QUOTE_ID: "BIKEQUOTE00141337",
      UNIQUE_QUOTE_ID_ENCRYPTED: "hFN__gQby3bVZMqwqw1s2qwqwIaCZobim__70XS7",
      contactInfo: {
        messageId: "",
        termsCondition: true,
        firstName: "Kuldeep",
        mobileNumber: "8578999999",
        createLeadStatus: false,
      },
      requestSource: "web",
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

//to generate bike proposal after 7 forms
export function generateBikeproposal(
  values,
  props,
  transactionName,
  singlerocord
) {
  localStorage.setItem("insuranceCompany", singlerocord?.insuranceCompany);
  let registrationNumber = values?.registeredRto;
  let dateOfBirth = convertDate(values?.kycNomineDob, 3);
  let vehicleLoanType = "";
  let financeInstitutionCode = values?.financierID;
  let financeInstitution = values?.financierName;
  let GSTIN = values.ownerGstin;
  let registrationValidityDate = "";
  if (
    singlerocord?.insuranceCompany === "Reliance General Insurance Co. Ltd" &&
    transactionName?.insuranceFor === "new"
  ) {
    registrationNumber = "NEW";
    console.log("registrationNumber", registrationNumber);
  }

  if (singlerocord?.insuranceCompany === "New India Assurance") {
    dateOfBirth = convertDate(values?.kycNomineDob, 1);
    vehicleLoanType = "Hypothecation";

  //  console.log("ggggggggggggg",transactionName.registrationYear);
    if(transactionName?.insuranceFor === "new"){
   
      let newDate=parseInt(transactionName.registrationYear)+15
      registrationValidityDate="01/01/"+newDate
    }
    else if (transactionName?.insuranceFor === "renew") {
      let newDate = new Date(transactionName.registrationYear);
      newDate.setFullYear(newDate.getFullYear() + 15);
  
      let day = newDate.getDate().toString().padStart(2, '0');
      let month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
      let year = newDate.getFullYear();
  
      registrationValidityDate = `${day}/${month}/${year}`;
  }
  }
  if (
    singlerocord?.insuranceCompany === "Reliance General Insurance Co. Ltd" ||
    singlerocord?.insuranceCompany === "GO DIGIT General Insurance CO. LTD"
  ) {
    vehicleLoanType = "Hypothecation";
  }
  if (values?.vehicalPurchaseLoan === "no") {
    vehicleLoanType = "NA";
  }
  if (
    values?.vehicalPurchaseLoan === "no" &&
    singlerocord?.insuranceCompany === "ICICI Lombard General Insurance Co. Ltd"
  ) {
    vehicleLoanType = "NA";
    financeInstitution = "NA";
    financeInstitutionCode = "NA";
  }

  if (
    singlerocord?.insuranceCompany ===
      "ICICI Lombard General Insurance Co. Ltd" &&
    !GSTIN
  ) {
    console.log("GSTIN2", GSTIN);

    GSTIN = undefined;
  }

  localStorage.setItem("type", transactionName?.formtype);

  const data = {
    header: {
      transactionName:
        transactionName?.formtype === "bike"
          ? "submitBikeProposal"
          : "carProposal",
      messageId: null,
      deviceId: "ABCD12345",
    },
    body: {
      nominationDetails: {
        nomPersonAge: gateage(values?.nomineDob),
        nomFirstName: values?.nomineFirstName,
        nomLastName: values?.nomineLastName,
        nomDateOfBirth: convertDate(values?.nomineDob, 3),
        nominationRelation: values?.nomineRelation,
        nominationRelationId: 1,
      },
      vehicleDetails: {
        purchasedLoan: values?.vehicalPurchaseLoan,
        vehicleLoanType: vehicleLoanType,
        financeInstitutionCode: values?.financierID,
        financeInstitution: values?.financierName,
        engineNumber: values?.engineNumber,
        isVehicleAddressSameAsCommun: values?.isRegisteredAddressSame,
        registrationAddress: {
          regArea: values?.ownerAddress,
          regPincode: values?.ownerPincode,
          regCity: values?.ownerCity,
          regDisplayArea: values?.ownerAddress,
          regDistrict: values?.ownerLocation,
          regDisplayField: values?.ownerAddress,
          regState: values?.ownerState,
          regDoorNo: values?.ownerFlatNumber,
        },
        chassisNumber: values?.chassisNumber,
        registrationNumber: registrationNumber,
        manufacturingYear: values?.vehicalManufaDate.split("-")?.[0],
      },
      proposerDetails: {
        lastName: values?.ownerLastName,
        middleName: values?.ownerMiddleName,
        gender: values?.kycNomineGender,
        mobileNumber: values?.ownerPhoneNumber,
        maritalStatus: values?.ownerMaritialStus,
        dateOfBirth: dateOfBirth,
        emailId: values?.ownerEmail,
        panNumber: values?.kycNominePanNumber,
        personAge: gateage(values?.kycNomineDob),
        firstName: values?.ownerFirstName,
        GSTIN: GSTIN,
        communicationAddress: {
          comArea: values?.ownerAddress,
          comPincode: values?.ownerPincode,
          comCity: values?.ownerCity,
          comDisplayArea: values?.ownerAddress,
          comDistrict: values?.ownerLocation,
          comDisplayField: values?.ownerAddress,
          comState: values?.ownerState,
          comDoorNo: values?.ownerFlatNumber,
        },
        salutation: values?.ownerPrefix,
        ckycNo: values?.kycFailedData,
      },
      source: "agency",
      requestSource: "agency",
      appointeeDetails: {},
      insuranceDetails: {
        policyNumber: values?.policyNumber,
        insuranceType: props?.carbikeformikValues?.insuranceFor,
        ncb: Number(
          props?.quotesPageFormikData?.noClaimBonus
            ? props?.quotesPageFormikData?.noClaimBonus
            : props?.carbikeformikValues?.noClaimBonus
        ),
        insurerName: values?.policyInsuranceId,
        insurerId:
          singlerocord?.insuranceCompany === "New India Assurance"
            ? 25
            : values?.policyInsuranceName,
        prevPolicyType: "Comprehensive Policy",
        TPPolicyNumber: values?.tpPolicyNumber,
        TPPolicyInsurer: values?.tpPolicyInsuranceName,
      },
      carrierId: props?.singleQuotesData?.carrierId,
      productId: props?.singleQuotesData?.productId,
      QUOTE_ID: props?.createquotesresult[0]?.QUOTE_ID
        ? props?.createquotesresult[0]?.QUOTE_ID
        : props.PolicyDates?.QUOTE_ID,
      businessLineId: transactionName?.formtype === "bike" ? 2 : 3,
      campaign_id: "97c0ef04-fcac-6b03-aa58-5c0512ad1884",
      registrationValidityDate: registrationValidityDate,
      // alternatePolicyExpDateFuture: values.alternatePolicyExpDateFuture,
      // existingPAPolicyNo: values.existingPAPolicyNo,
      // existingPASumInsured: values.existingPASumInsured,
      // insurerName: values.insurerName,
      // validLicense: values.validLicense,
      AlternatePolicyExpiryDate: values?.alternatePolicyExpDateFuture,
      ExistingPAPolicyNo: values?.existingPAPolicyNo,
      ExistingPASumInsured: values?.existingPASumInsured,
      InsurerName: values?.insurerName,
      ValidLicense: values?.validLicense,
    },
  };

  const data1 = {
    header: {
      transactionName:
        transactionName?.formtype === "bike"
          ? "submitBikeProposal"
          : "carProposal",
      messageId: null,
      deviceId: "ABCD12345",
    },
    body: {
      nominationDetails: {
        nomPersonAge: gateage(values?.nomineDob),
        nomFirstName: values?.nomineFirstName,
        nomLastName: values?.nomineLastName,
        nomDateOfBirth: convertDate(values?.nomineDob, 3),
        nominationRelation: values?.nomineRelation,
        nominationRelationId: 1,
      },
      vehicleDetails: {
        purchasedLoan: values?.vehicalPurchaseLoan,
        vehicleLoanType: vehicleLoanType,
        financeInstitutionCode:
          values?.financierID === "" ? "NA" : values?.financierID,
        financeInstitution:
          values?.financierName === "" ? "NA" : values?.financierName,
        engineNumber: values?.engineNumber,
        isVehicleAddressSameAsCommun: values?.isRegisteredAddressSame,
        registrationAddress: {
          regArea: values?.ownerAddress,
          regPincode: values?.ownerPincode,
          regCity: values?.ownerCity,
          regDisplayArea: values?.ownerAddress,
          regDistrict: values?.ownerLocation,
          regDisplayField: values?.ownerAddress,
          regState: values?.ownerState,
          regDoorNo: values?.ownerFlatNumber,
        },
        chassisNumber: values?.chassisNumber,
        registrationNumber: registrationNumber,
        manufacturingYear: values?.vehicalManufaDate.split("-")?.[0],
      },
      proposerDetails: {
        lastName: values?.ownerLastName,
        middleName: values?.ownerMiddleName,
        gender: values?.kycNomineGender,
        mobileNumber: values?.ownerPhoneNumber,
        maritalStatus: values?.ownerMaritialStus,
        dateOfBirth: dateOfBirth,
        emailId: values?.ownerEmail,
        panNumber: values?.kycNominePanNumber,
        personAge: gateage(values?.kycNomineDob),
        firstName: values?.ownerFirstName,
        GSTIN: GSTIN,
        communicationAddress: {
          comArea: values?.ownerAddress,
          comPincode: values?.ownerPincode,
          comCity: values?.ownerCity,
          comDisplayArea: values?.ownerAddress,
          comDistrict: values?.ownerLocation,
          comDisplayField: values?.ownerAddress,
          comState: values?.ownerState,
          comDoorNo: values?.ownerFlatNumber,
        },
        salutation: values?.ownerPrefix,
        ckycNo: values?.kycFailedData,
      },
      source: "agency",
      requestSource: "agency",
      appointeeDetails: {},
      insuranceDetails: {
        policyNumber: values?.policyNumber,
        insuranceType: props?.carbikeformikValues?.insuranceFor,
        ncb: Number(
          props?.quotesPageFormikData?.noClaimBonus
            ? props?.quotesPageFormikData?.noClaimBonus
            : props?.carbikeformikValues?.noClaimBonus
        ),
        insurerName: values?.policyInsuranceId,
        insurerId:
          singlerocord?.insuranceCompany === "New India Assurance"
            ? 25
            : values?.policyInsuranceName,
        prevPolicyType: "Comprehensive Policy",
        TPPolicyNumber: values?.tpPolicyNumber,
        TPPolicyInsurer: values?.tpPolicyInsuranceName,
      },
      carrierId: props?.singleQuotesData?.carrierId,
      productId: props?.singleQuotesData?.productId,
      QUOTE_ID: props?.createquotesresult[0]?.QUOTE_ID
        ? props?.createquotesresult[0]?.QUOTE_ID
        : props.PolicyDates?.QUOTE_ID,
      businessLineId: transactionName?.formtype === "bike" ? 2 : 3,
      campaign_id: "97c0ef04-fcac-6b03-aa58-5c0512ad1884",
      registrationValidityDate: registrationValidityDate,

    },
  };

  if (sessionStorage.getItem("id")) {
    data.body.proposerDetails["agentId"] = sessionStorage.getItem("id");
    data1.body.proposerDetails["agentId"] = sessionStorage.getItem("id");
  }
  if (sessionStorage.getItem("agencyId")) {
    data.body.proposerDetails["agencyId"] = sessionStorage.getItem("agencyId");
    data1.body.proposerDetails["agencyId"] = sessionStorage.getItem("agencyId");
  }
  if (sessionStorage.getItem("branchId")) {
    data.body.proposerDetails["branchId"] = sessionStorage.getItem("branchId");
    data1.body.proposerDetails["branchId"] = sessionStorage.getItem("branchId");
  }

  if(localStorage.getItem("dealId") && localStorage.getItem("dealId")!== null && localStorage.getItem("dealId")!== undefined){
    data.body.proposerDetails["dealId"]= localStorage.getItem("dealId")
    data1.body.proposerDetails["dealId"]= localStorage.getItem("dealId")
  }

  if (
    singlerocord?.insuranceCompany === "ICICI Lombard General Insurance Co. Ltd"
  ) {
    return axios.post(projectBaseurl + getRtodetails, data1);
  } else {
    return axios.post(projectBaseurl + getRtodetails, data);
  }
}

//to get payment url
export function paymentApi(values) {
  console.log("paymentApi", values);
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "paymentService",
    },

    body: values,
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

// to download policy
export function downloadPolicy(mobileNumber) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "transDataReader",
    },
    body: {
      docId: `PolicyDetails-${
        mobileNumber ? mobileNumber : localStorage.getItem("ownerPhoneNumber")
      }`,
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
export function kycVeryfy(values) {
  const data = {
    URL: process.env.REACT_APP_RELIANCE_KYC_URL,
    request: {
      PAN: values.kycNominePanNumber,
      DOB: convertDate(values.kycNomineDob, 3),
      CKYC: "",
      MOBILE: "",
      PINCODE: "",
      BIRTHYEAR: "",
      ReturnURL: "test",
      UNIQUEID: Math.floor(100000 + Math.random() * 900000),
      CIN: "",
      VOTERID: "",
      "DL_No ": "",
      PASSPORT: "",
      AADHAAR_NO: "",
      FULLNAME: "",
      GENDER: "",
    },
    headers: {
      "Subscription-Key": process.env.REACT_APP_RELIANCE_KYC_URL_Subscription,
      "Content-Type": "application/json",
    },
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function getFullAddress(pincode) {
  return axios.get(addressUrl + pincode);
}

export function getMakeModel(type) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: "",
      documentType: type,
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

export function vahanDataCounch(id, type) {
  const data = {
    documentId: `${type === "2WN" ? "BikeVarientId-" : "CarVarientId-"}` + id,
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

// future kyc api to create token
export function getTokenForFutureApi() {
  const data = {
    URL: "https://fgnluat.fggeneral.in/NLCKYC/API/CKYC/GenerateToken",
    request: {},
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
// future kyc api to get kyc details
export function getKycFutureApi(key, values) {
  const data = {
    URL: "https://fgnluat.fggeneral.in/NLCKYC/API/CKYC/CreateCKYC",
    request: {
      req_id: key,
      customer_type: "I",
      id_type: "PAN",
      id_num: values?.kycNominePanNumber,
      full_name: values?.kycNomineFullName,
      gender: values?.kycNomineGender.charAt(0).toUpperCase(),
      dob: convertDate(values?.kycNomineDob, 3),
    },
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

// get state
export function getState() {
  const data = {
    documetType: "State",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
// get state
export function getCity(state) {
  const data = {
    documetType: "State",
    state: state,
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function getPincodeData(values) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: values,
      documentType: "Pincode",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

export function loanFinancer() {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: "",
      documentType: "LoanFinancer",
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
export function loanFinancerHDFC(searchString) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue:
        searchString === null || searchString === undefined ? "" : searchString,
      documentType: "LoanFinancerInfo",
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// to get list of all riders
export function getpolicyProviderName(name) {
  // const data = {
  //     "documentType": "InsuranceMapping",
  //     "insurerName": "",
  //     "InsurerCode": ""
  // }
  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": 47,
    "New India Assurance": 25,
    "Future Generali India Insurance Co. Ltd": 37,
    "GO DIGIT General Insurance CO. LTD": 61,
    "ICICI Lombard General Insurance Co. Ltd": 29,
    "HDFC ERGO General Insurance Company": 28,
    "Bajaj Allianz General Insurance Co. Ltd": 52,
  };
  console.log("imgArrayForCard?.[name]", imgArrayForCard?.[name]);
  const data = {
    documentType: "Carrier",
    selectedCarrier: imgArrayForCard?.[name],
  };
  return axios.post(projectBaseurl + getpolicyName, data);
}

// to get list of all riders
export function shareQuotes(quotesID = "BIKEQUOTE00175904") {
  const data = {
    header: {
      transactionName: "quoteDataReader",
      messageId: "",
      deviceId: "ABCD12345",
    },
    body: {
      docId: quotesID,
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

export function mobileNumber(action, number, enteredOTP) {
  const data = {
    action: action,
    mobileNumber: number,
    enteredOTP: action === "sendOTP" ? null : enteredOTP,
  };
  return axios.post(projectBaseurl + mobileNumberurl, data);
}

export function downloadPolicyDoc(mobileNumber, pKey) {
  const data = {
    header: {
      transactionName: "policyDocDownloadService",
      messageId: "",
      deviceId: "ABCD12345",
    },
    body: {
      mobile: localStorage.getItem("ownerPhoneNumber"),
      pKey: pKey,
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// to generate token for icici kyc
export function iciciKycToken() {
  const data = {
    request: {
      client_id: "NavneetIns",
      client_secret: "prvkPbG6Hv8XXvcUusUq6udV",
      grant_type: "password",
      password: "QKLbFFtLF9RbAR9",
      scope: "esb-kyc",
      username: "NavneetIns",
    },
    headers: {
      CamelHttpMethod: "POST",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    URL: "https://ilesbsanity.insurancearticlez.com/cerberus/connect/token",
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

// to generate token for icici kyc
export function iciciKycVerify(token, values) {
  const data = {
    request: {
      certificate_type: "PAN",
      correlationId: "UUID",
      pan_details: {
        dob: convertDate(values?.kycNomineDob, 3),
        pan: values?.kycNominePanNumber,
      },
      pep_flag: false,
    },
    URL: "https://ilesbsanity.insurancearticlez.com/ilservices/customer/v1/kyc/initiate",
    carrierId: 29,
    headers: {
      Authorization: "Bearer " + token,
      CamelHttpMethod: "POST",
      "Content-Type": "application/json",
    },
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function iciciKycDocSubmit(token, email, icicidoc) {
  let formData = new FormData();
  formData.append("mobile_number", localStorage.getItem("ownerPhoneNumber"));
  formData.append("email", email);
  formData.append("is_poa_poi_same", false);
  formData.append("poi[0].certificate_type", icicidoc?.poitype);
  formData.append("poi[0].document", icicidoc?.poiFile);
  formData.append("poa[0].certificate", icicidoc?.poatype);
  formData.append("poa[0].document", icicidoc?.poaFile);
  formData.append("CorrelationId", "fda0df66-beaf-4365-9197-1bf4f7cec720");
  formData.append("customer_type", "I");

  console.log("formdata", formData);
  console.log("email", email);
  console.log("icicidoc", icicidoc);

  const data = {
    request: formData,
    URL: "https://ilesbsanity.insurancearticlez.com/ilservices/customer/v1/kyc/ovd",
    headers: {
      Authorization: "Bearer " + token,
      CamelHttpMethod: "POST",
      "Content-Type": "application/json",
    },
  };
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  // return axios.post(projectBaseurl + kycBaseurl, data,)
  return axios.post(
    "https://ilesbsanity.insurancearticlez.com/ilservices/customer/v1/kyc/ovd",
    formData,
    config
  );
}

//to get proposal list
export function getProposalList(mob) {
  console.log("paymentApi", mob);
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      mobile: mob,
      documentType: "proposalInfo",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

//to get proposal details
export function getProposal(values) {
  console.log("paymentApi", values);
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "transDataReader",
    },
    body: {
      docId: values,
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

export function vahanDataCouchbase(vehNum) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "getvehiclertodetails",
    },
    body: {
      task: "detailedSearch",
      essentials: {
        vehicleNumber: vehNum,
        signzyID: "AKYTSZY",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

export function listsForAgent(dataReq) {
  console.log("list for agent API", dataReq?.agentID, dataReq?.operationType);
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "profileData",
    },
    body: {
      agentId: dataReq?.agentID ? dataReq?.agentID : "",
      mobile: dataReq?.mobileNo ? dataReq?.mobileNo : "",
      policyIssueDate: dataReq?.date ? dataReq?.date : "",
      phoneNumber: "",
      operationType: dataReq?.operationType ? dataReq?.operationType : "",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
// CRM api start
export function crmLogin() {
  const data = {
    username: "navnit_api",
    password: "navnit@api123",
  };
  return axios.post("https://navnit.skycrm.app/get_token", data);
}

export function crmGetLoginUser(token) {
  console.log("token", token);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get("https://navnit.skycrm.app/getloginuser", config);
}

export function crmCreateLead(token, data) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // const data = {
  //   customer_name: "Abhishek",
  //   mobile_number: "9850011010",
  //   caller_name: "NIB048",
  //   email_id: "ab@gmail.com",
  //   residential_address: "wadgaon BK",
  //   date_of_birth: "27/07/1994",
  //   pan_number: "ASGPC1920R",
  //   Policy_Exp_date: "29/06/2024",//pass previous policyExp//pratik need to check
  //   policy_no: "D153506700",//pass previous policy no//pratik need to check
  //   insurance_company: "GO DIGIT General Insurance CO. LTD",
  //   state: "MAHARASHTRA",
  //   pincode: "411041",
  //   RTO_no: "MH01AA4489"
  // }
  return axios.post(
    "https://navnit.skycrm.app/createleadinmotor",
    data,
    config
  );
}

export function crmLeadUpdate(token, data) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.patch(
    "https://navnit.skycrm.app/createleadinmotor",
    data,
    config
  );
}

export function crmStageUpdateApi(token, data) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(
    "https://navnit.skycrm.app/additionaltwo_update",
    data,
    config
  );
}

export function newIndVahanApi(vehicalNumber) {
  const data = {
    request: {
      PartnerID: "POLICY365",
      VehicleNumber: vehicalNumber,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic VUFUUkNWOk5pYUAxMjM0",
    },
    URL: "https://uatb2bug.newindia.co.in/b2b/rcVerification",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function godigitTokenApi() {
  const data = {
    request: {
      username: "35327650",
      password: "Digit@123$",
    },
    headers: {
      "Content-Type": "application/json",
      Cookie:
        "TS017fdda2=0138ecebf9cbb82f4a85e20d657b10f88a73d00f5597bd5c1232b03e89050a0b945a17683a80b32f806330645eb0e3a5726e98638e",
    },
    URL: "https://preprod-oneapi.godigit.com/OneAPI/digit/generateAuthKey",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function godigitBreakInstaus(token, polNumber) {
  const data = {
    request: {
      queryParam: {
        policyNumber: polNumber,
      },
    },
    headers: {
      "Content-Type": "application/json",
      integrationId: "22795-0100",
      Authorization: `Bearer ${token}`,
      Cookie:
        "TS017fdda2=0138ecebf9cbb82f4a85e20d657b10f88a73d00f5597bd5c1232b03e89050a0b945a17683a80b32f806330645eb0e3a5726e98638e",
    },
    URL: "https://preprod-oneapi.godigit.com/OneAPI/v1/executor",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
//to get My Profile details
export function getMyProfile(mobileNumber) {
  console.log("getmyProfile mobile", mobileNumber);
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "transDataReader",
    },
    body: {
      docId: `UserProfile-${mobileNumber}`,
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
//update Profile details
export function updateMyProfileApi(values) {
  console.log("values", values);

  const data = {
    action: "updateUserProfile",
    firstName: values?.firstName,
    lastName: values?.lastName,
    mobileNumber: values?.mobileNumber,
    gender: values?.gender,
    emailId: values?.EmailId,
    maritalStatus: values?.maritalStatus,
  };
  console.log("obj for update", data);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(projectBaseurl + mobileNumberurl, data, config);
}
export function godigitPayment(token, id, proposalNo, type) {
  const data = {
    request: {
      successReturnUrl:
        projectBaseurl +
        `/cxf/paymentresponse/service/success/obengen/${
          type === 3 ? "car" : "bike"
        }?ProposalNo=` +
        proposalNo,
      applicationId: id,
      cancelReturnUrl:
        projectBaseurl +
        `/cxf/paymentresponse/service/failure/obengen/${
          type === 3 ? "car" : "bike"
        }?ProposalNo=` +
        proposalNo,
      paymentMode: "EB",
    },
    headers: {
      "Content-Type": "application/json",
      integrationId: "23216-0100",
      Authorization: `Bearer ${token}`,
      Cookie:
        "TS017fdda2=0138ecebf9eb49b41bfc9122f3e475ec4367647b7db7fbc614603ffae5146fd1a7fab5a9fb9be7628089b960e25739a71372cd4856; TS017fdda2=0138ecebf996485ac152f34a2fcbd10c3abfed242aed6258537fedac9ff3a21b82280ae8e29142ba5cf11eefeb025c976df69edaf8",
    },
    URL: "https://preprod-oneapi.godigit.com/OneAPI/v1/executor",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
