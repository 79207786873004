import "./App.css";
import { useState, useEffect } from 'react'
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { routesArray } from "./routing/RoutingPaths";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/commonModules/Header";
import { FormProvider } from "../src/context/FormContext";
import "../src/style.scss";
import AutoLogout from "./components/AutoLogOutFolder/AutoLogOutFile";
import { Notification } from "./components/Notification/Notification";
import { Footer } from "./components/pages/Footer";
import UserContext from "./components/Context/UserContext";
import { MyProfile } from "./components/pages/MyProfile";
import { MyProfileAdmin } from "./components/pages/MyprofileAdmin";
import { useLocation } from "react-router";
import { RoutingMap } from "../src/routing/Routingmap";
import "../src/components/style/assets/js/vendor/tailwindcss3.4.5.js";


function App() {

    const [userRole, setUserRole] = useState("");
   
    const setUser = (role) => {
      console.log("role::::", role)
      //userRole=role;
      setUserRole(role);
    };
   
    return (
      <FormProvider>
        <div className="App">
          <BrowserRouter>
            <AutoLogout />
            <Header />
            <div className="height-container">
              <Switch>
                <UserContext.Provider value={{ userRole, setUser }}>
                 <RoutingMap></RoutingMap>
                </UserContext.Provider>
              </Switch>
            </div>
            <Footer />
          </BrowserRouter>
        </div>
        <Notification />
      </FormProvider>
    );
  }

export default App;
