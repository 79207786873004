import React from "react";
import UseFormContext from "../../context/UseFormContext";
import { Row,Col,  Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import InputFieldText from "../commonModules/InputFieldText";
import { Margin } from "react-to-pdf";

export default function PaymentForm(props,proposalNo) {
  const formContext = UseFormContext();
  console.log("iciciData",formContext?.iciciData,formContext?.isLastStep)

  return (
    <React.Fragment>
      <div>
        <h2>Click the finish button to Proceed for payment</h2>
        {formContext?.singleQuotesData.insuranceCompany ===
          "ICICI Lombard General Insurance Co. Ltd" && !formContext.loaderStatus&& (
          <div>
            <Card style={{marginTop:"10%"}}>
              <CardHeader>
                <h5>Proposal Details</h5>
              </CardHeader>
              <CardBody>
              <Row>
                <Col>
                  <Card.Text>
                    <span className="help-text">Proposal Number : </span>
                    <span>{formContext?.iciciData?.AdditionalInfo1}
                 
                    </span>
                  </Card.Text>
                </Col>

                <Col>
                  <Card.Text>
                    {" "}
                    <span className="help-text">Poposal Amount : </span>
                    <span>{formContext?.iciciData?.Amount}
                    
                    </span>
                  </Card.Text>
                </Col>
              </Row>
                <Row>

                </Row>
              </CardBody>
            </Card>
          </div>
        )}

        {formContext.loaderStatus && formContext?.singleQuotesData.insuranceCompany ===
          "ICICI Lombard General Insurance Co. Ltd" && formContext?.isLastStep &&(
          <div>
            {formContext.spinner}
            <br />
            please wait while we create Proposal .
          </div>
        )}
         {formContext.loaderStatus && formContext?.singleQuotesData.insuranceCompany !==
          "ICICI Lombard General Insurance Co. Ltd" &&(
          <div>
            {formContext.spinner}
            <br />
            please wait, we are redirecting you to payment page.
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
